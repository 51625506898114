.custom-action {
  .col {
    width: 320px;
  }

  &-btn-col {
    .text {
      text-align: right;
    }
  }
  &-preview-col {
    a:hover {
      text-decoration: none;
    }
  }
}
