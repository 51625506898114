@import '~/client/src/shared/theme.module';

.permissions-grid {
  .bp3-control.bp3-radio {
    margin: 0;
    padding-left: 60px;
  }

  .tool-icon-wrapper {
    background-color: get_color('neutral', 0);

    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;

    color: get_color('neutral', 100);

    svg,
    svg path {
      height: 10px;
      width: 10px;
      stroke: get_color('neutral', 100);
      fill: get_color('neutral', 100);
    }
  }

  .tool-cell {
    max-width: 120px;
    min-width: 120px;
  }

  .workflow-cell {
    max-width: 180px;
    min-width: 180px;
  }

  .bp3-control input:checked ~ .bp3-control-indicator {
    background-color: get_color('neutral', 100);
  }

  .bp3-control.bp3-radio input:checked ~ .bp3-control-indicator::before {
    background-image: radial-gradient(
      get_color('primary', 50),
      get_color('primary', 50) 28%,
      transparent 32%
    );
  }

  .bp3-control input:checked ~ .bp3-control-indicator {
    box-shadow: inset 0 0 0 1px get_color('primary', 50),
      inset 0 -1px 0 get_color('primary', 50);
  }
}
