@import '~/client/src/shared/theme.module';

.compact-alert {
  min-height: 140px;

  svg {
    color: get_color('secondary', 40);
  }

  .handle-button {
    background-color: get_color('neutral', 100);
    color: get_color('primary', 30);
    border-color: get_color('primary', 30);
  }
}
