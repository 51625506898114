.permit-type-instructions {
  margin-top: 12px;

  .instruction-item {
    select {
      min-width: 190px;
    }

    .del-icon {
      right: 0;
    }
  }
}
