@import '~/client/src/shared/theme.module';

.tabs-container {
  nav.tab {
    min-height: 32px;
    max-height: 32px;

    padding: 0 16px;

    border-right: none;
    border-left: none;
    border-top: none;

    &.active {
      border-radius: 6px 6px 0 0;
      background-color: get_color('primary', 90, 0.5);
      border-bottom-color: get_color('primary', 30);
      box-shadow: inset 0 -1px get_color('primary', 30);
      span,
      svg {
        color: get_color('primary', 30);
        fill: get_color('primary', 30);
      }
    }
  }
}
