@import '~/client/src/shared/theme.module';

$hour-labels-width: 70px;
$day-labels-height: 60px;
$weather-label-height: 32px;
$gmt-label-top-offset: 40px;
$vertical-total-bar-width: 400px;
$horizontal-total-bar-height: 170px;

$working-area-height: calc(
  100% - #{$day-labels-height} - #{$weather-label-height}
);

$scroll-resizer-width: 8px;
$total-item-width: 70px;
$collapse-icon-offset: 4px;

// it needs to be synced with "hlMarginTopOffset" on DesktopCalendar.tsx :
$hours-label-margin-top-offset: 5px;

.bp3-tooltip-calendar-event {
  .bp3-popover-target {
    width: 100%;
    height: 100%;
    .expandable-workflow-card {
      max-width: 350px;
    }
  }
}

.desktop-calendar-view {
  height: 100%;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .arrow-icon {
    font-size: 12px;
    color: get_color('primary', 30);
    cursor: pointer;
  }

  .highlighted {
    padding: 0 5px;
    border-radius: 7px;
    background-color: get_color('error', 50);
  }

  .x-axis-column-width {
    min-width: 150px;
  }

  &.vertical-total-bar {
    .days-container {
      width: calc(100% - #{$hour-labels-width + $vertical-total-bar-width});
    }

    $day-mode-height: calc(100% - #{$day-labels-height}) !important;

    .gmt-timezone {
      top: 0 !important;
    }

    .hour-labels-container {
      height: $day-mode-height;
      margin-top: $day-labels-height - 5px;
    }

    .days-container .days-content {
      height: $day-mode-height;
    }
  }

  &-horizontal-total-bar {
    height: $horizontal-total-bar-height;
    transition: height 0.2s;
    &.collapsed {
      height: 25px;
    }
    .collapse-menu {
      position: absolute;
      top: $collapse-icon-offset;
      left: #{$hour-labels-width + $collapse-icon-offset};
      z-index: 1;
    }

    &-left-column {
      height: 100%;
      min-width: $hour-labels-width;
      max-width: $hour-labels-width;
      padding-left: 3px;

      .total-item {
        width: #{$hour-labels-width - 10};
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &-content {
      margin-right: $scroll-resizer-width;
      overflow-x: auto;
      height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &-vertical-total-bar {
    transition: min-width 0.2s;
    min-width: $vertical-total-bar-width;
    height: 100%;
    &.collapsed {
      min-width: 40px;
      height: 100%;
    }
    .collapse-menu {
      position: absolute;
    }

    .total-item-width {
      flex-basis: $total-item-width;
    }

    &-header {
      height: $day-labels-height;
      margin-top: 6px;
    }

    &-content {
      height: $working-area-height;
      overflow-y: auto;
      padding-bottom: 50px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .gmt-timezone {
    position: absolute;
    top: $gmt-label-top-offset;
    &.top5 {
      top: 5px;
    }
  }

  .hour-labels-container {
    flex: 1 1 $hour-labels-width;
    overflow-y: auto;
    height: $working-area-height;
    margin-top: $day-labels-height + $weather-label-height -
      $hours-label-margin-top-offset;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .today-dot {
    width: 20px;
    height: 20px;
    background-color: get_color('secondary', 40);
    border-radius: 55%;
    position: absolute;
    z-index: 1;
  }

  .days-container {
    width: calc(100% - #{$hour-labels-width});
    height: 100%;

    .day-labels-container {
      width: calc(100% - #{$scroll-resizer-width});
      height: $day-labels-height;
      overflow-x: auto;

      .date-label {
        z-index: 2;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .weather-labels-container {
      margin-right: $scroll-resizer-width - 1;
      height: $weather-label-height;
      overflow-x: auto;

      .weather-label:last-child {
        border-right: 1px solid get_color('grey', 84);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .md-events-bar {
      margin-right: $scroll-resizer-width - 1;
    }

    .days-content {
      height: $working-area-height;
      padding-bottom: 20px;
      width: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        width: $scroll-resizer-width;
        height: $scroll-resizer-width;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: get_color('primary', 30);
      }
    }
  }
}
