@import '~/client/src/shared/theme.module';

.upload-button {
  &-icon {
    background-image: url(/static/icons/download.svg);
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
  &-text {
    padding-left: 5px;
  }
}
