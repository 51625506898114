@import '../FilterCommon';

.filter-header {
  height: $FILTER_HEADER_HEIGHT;
  padding: 10px 15px 0 15px;

  &-btn {
    width: 85px;
    height: 19px;
    margin-right: 5px;
    border-radius: 2px;
    border: solid 1px get_color('primary', 30);
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: get_color('neutral', 100);
    color: get_color('primary', 30);
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: get_color('primary', 88, 0.5);
    }
  }

  &-hint {
    font-weight: 600;
    color: get_color('neutral', 0);
    text-transform: uppercase;
    margin-left: 53px;

    &.code {
      max-width: 110px;
    }

    &.act {
      max-width: 125px;
      text-align: right;
      margin-left: 0;
    }

    &.code + &.desk {
      margin-left: 0;
    }
  }
}
