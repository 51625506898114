@import '~/client/src/shared/theme.module';

$padding-y: 5px;
$border-width: 2px;
$padding-y-highlighted: $padding-y + $border-width;
$border-radius: 4px;

.level-drag-handle {
  z-index: 100;
  &-active {
    z-index: 101 !important;
  }
}

.hierarchy-tree-node-wrapper {
  padding-top: $padding-y;
  padding-bottom: $padding-y;

  border: $border-width solid transparent;

  &.selected {
    background-color: get_color('primary', 96);

    svg {
      background-color: get_color('primary', 96);
    }
  }

  &:hover {
    background-color: get_color('primary', 96);

    svg {
      background-color: get_color('primary', 96);
    }
  }

  &.top-highlight {
    border-bottom: none;
    padding-bottom: $padding-y-highlighted;
    border-color: get_color('primary', 50);

    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  &.x-highlight {
    border-bottom: none;
    border-top: none;

    padding-top: $padding-y-highlighted;
    padding-bottom: $padding-y-highlighted;

    border-right-color: get_color('primary', 50);
    border-left-color: get_color('primary', 50);
  }

  &.bottom-highlight {
    border-top: none;
    padding-top: $padding-y-highlighted;
    border-color: get_color('primary', 50);

    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

.level-line {
  position: absolute;
  top: -2px;
  height: calc(100% + 4px);
  width: 1px;
  background-color: get_color('neutral', 84);
}
