@import '~/client/src/shared/theme.module';

.filter {
  &-container {
    position: absolute;
    width: 497px;
    height: calc(100vh - 320px);
    top: 100%;
    background-color: get_color('neutral', 100);
    z-index: 100;
    border: 1px solid get_color('neutral', 70);
    border-radius: 2px;
    line-height: 17px;
    color: get_color('primary', 30);
    &.short {
      height: 95px;
    }

    // Checkbox customization according to mock-up
    .checkbox-container {
      &.extra-large {
        padding-left: 40px;
        font-size: 14px;
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .checkmark {
          border-radius: 3px;
          background-color: get_color('neutral', 100);
          &:after {
            left: 4.5px;
            top: 2px;
            width: 4px;
            height: 7px;
            border-width: 0 1px 1px 0;
            border-color: get_color('primary', 30);
          }
        }
      }
    }
  }

  &-content {
    height: calc(100% - (#{$FILTER_HEADER_HEIGHT} + #{$FILTER_FOOTER_HEIGHT}));
    overflow-y: scroll;
  }

  &-loader {
    margin: 35px auto;
  }

  &-notification {
    text-align: center;
  }
}
