@import '~/client/src/shared/theme.module';

.icon-filter {
  flex-grow: 0;
  flex-basis: 100px;
  position: relative;

  .icon-options-list {
    position: absolute;
    background-color: get_color('neutral', 100);
    z-index: 99;
    box-shadow: 0px 20px 50px -20px get_color('neutral', 50);
  }
}
