@import '~/client/src/shared/theme.module';

.upload-schedule {
  &-block {
    position: relative;
    width: 550px;
    height: 350px;
    flex-basis: auto;
    margin: auto;
    color: get_color('neutral', 20);
    font-size: 16px;
    &-bordered {
      border: 2px dashed get_color('primary', 30);
    }

    &.small {
      height: 85px;
    }
  }

  &-dnd-container {
    width: 100%;
    font-size: 14px;
    position: relative;
  }
}
