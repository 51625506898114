.object-properties-setup .objects-properties-filter-panel {
  .activity-name-filter {
    flex-grow: 0 !important;
    margin-right: 10px;
  }

  .location-filter .gant-header-btn {
    margin-left: 0px;
    margin-right: 10px;
  }
}
