@import '~/client/src/shared/theme.module';

$side-bar-width: 190px;
$map-action-bar-height: 52px;

.desktop-deliveries {
  &-date-picker {
    position: absolute;
    z-index: 99;
    right: 40px;
    border: 1px solid get_color('neutral', 70);
  }

  &-top-bar {
    .base-arrow {
      svg path {
        fill: get_color('neutral', 40);
      }
    }

    &-mode-picker {
      border-radius: 3px;
      overflow: hidden;
    }

    &-btn {
      border: 1px solid;
      border-color: get_color('neutral', 84);
      padding: 5px 15px;
      border-radius: 3px;
      font-size: 14px;
      color: get_color('neutral', 50);
      cursor: pointer;

      &.no-rounds {
        border-radius: 0;
      }

      &.date-picker-button {
        min-width: 130px;
        max-width: 130px;
        padding: 5px 0;
        background-color: get_color('neutral', 100);
        &.active-current-day {
          border-color: get_color('secondary', 40);
          background-color: get_color('secondary', 96);
          color: get_color('secondary', 40);
        }
      }

      &.active {
        border-color: get_color('primary', 30);
        background-color: get_color('primary', 30);
        color: get_color('neutral', 100);
      }
    }

    &-add-button {
      cursor: pointer;

      &.active,
      &:hover {
        .desktop-deliveries-top-bar-add-button-plus {
          background-color: get_color('primary', 88, 0.5);
        }
      }

      &-plus {
        background-color: get_color('neutral', 100);
        display: inline-block;
        border: 1px solid get_color('primary', 30);
        border-radius: 50%;
        padding: 4px;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        box-shadow: 0px 0px 34px 0px get_color('neutral', 0, 0.6);

        svg {
          transform: scale(0.7);
        }
      }
    }
  }

  &-details {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border-left: 1px solid get_color('neutral', 84);
    width: 400px;
    background-color: get_color('neutral', 100);
    z-index: 98;
  }

  .deliveries-list-container {
    height: 100%;
    .table-holder {
      align-items: start;
      overflow-x: auto;
      overflow-y: hidden;
      height: 100%;
    }

    .selection-pop-up {
      z-index: 11;
    }
  }

  .multi-grid-container {
    .cell-header {
      font-weight: 600;
      border-bottom: 1px solid get_color('neutral', 0);
    }

    .cell.category-cell svg {
      color: unset;
    }
  }

  .header-bar {
    .overflowed-filters-list {
      min-width: 200px;
    }

    .overflowed-filters-modal {
      max-height: 480px;
      max-width: 350px;
      z-index: 100;
    }

    .date-mode-btn {
      height: 32px;
      border: 1px solid get_color('neutral', 84);
    }

    .date-mode-options-container {
      top: 30px;
      z-index: 101;
      left: -1px;
      box-shadow: 0 14px 28px get_color('neutral', 0, 0.25),
        0 10px 10px get_color('neutral', 0, 0.25);

      .bp3-icon {
        padding: 0 5px;
      }

      .option {
        height: 32px;

        &:hover {
          background-color: get_color('primary', 90);
        }

        &.selected {
          color: get_color('neutral', 100);
          border-color: get_color('primary', 30);
          background-color: get_color('primary', 30);
        }
      }
    }
  }

  .deliveries-map-action-bar {
    height: $map-action-bar-height;
  }

  .deliveries-map-sidebar {
    padding-top: $map-action-bar-height;
  }
}
