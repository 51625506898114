@import '~/client/src/shared/theme.module';

$late-icon-width: 28px;
$handle-button-width: 50px;
$handle-button-span-size: 20px;
$selector-width: 155px;
.category-filter {
  &-handle-button {
    max-width: $handle-button-width;
    min-width: $handle-button-width;

    span.circle {
      max-width: $handle-button-span-size;
      min-width: $handle-button-span-size;
      max-height: $handle-button-span-size;
      min-height: $handle-button-span-size;
      padding: 0;
      border-radius: 50%;
      background-color: inherit;
    }
  }
  .option-row {
    height: 24px;
    .icon-cell {
      max-width: 10%;
      flex-basis: 10%;
      min-width: 10%;
      .late {
        width: $late-icon-width;
        color: get_color('neutral', 100);
        background-color: get_color('error', 50);
      }
    }
    .name-cell {
      flex-basis: 80%;
    }
    .recently-name-cell {
      min-width: 120px;
    }
    .status-name-cell {
      min-width: 180px;
    }
    .selector-cell {
      &-name {
        min-width: 40px;
        max-width: 40px;
      }
      flex-basis: 45%;
      .selector {
        height: 24px;
      }
      .title {
        min-width: $selector-width;
      }
    }
    .amount-cell {
      flex-basis: 10%;
      max-width: 10%;
    }
  }
}
.calendar-holder {
  position: absolute;
  top: 20px;
  left: 430px;
  z-index: 1;
}
.date-selector-options-holder {
  position: relative;
  top: -1px;
  left: -6px;
  z-index: 1;
  .option {
    width: $selector-width;
    height: 24px;
    &:hover {
      background-color: get_color('primary', 80);
    }
  }
}
