@import '~/client/src/shared/theme.module';

.expression-control-container {
  .tag-list {
    min-height: $NOTIFICATION_RULE_CONTAINERS_HEIGHT;
    min-width: 350px;

    .expr-element {
      height: 32px;
    }
  }

  .tags-by-group-modal-wrapper {
    top: 20px;
    right: 210px;
  }

  .add-tag-pull-down-handle {
    &.active,
    &:hover {
      background-color: get_color('primary', 96);
    }

    .user-icon,
    .user-icon svg {
      width: 12px !important;
      height: 12px !important;
      fill: get_color('neutral', 50);
    }
  }

  .users-tags-selector {
    top: 100%;
    left: 0;
    max-height: 350px;
    z-index: 3;
    background-color: get_color('neutral', 100);
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 5px 0 get_color('neutral', 84);
  }

  .users-directory-selector {
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    max-height: 400px;

    .search-bar-container {
      background: get_color('neutral', 100) !important;
      padding: 10px !important;
    }

    .users-group-by {
      top: 72px;
      right: 114px;
      z-index: 4;
    }
  }
}
