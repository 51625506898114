@import '~/client/src/shared/theme.module';

$name-container-width: 400px;
$name-container-height: 200px;

%collapsed-state {
  .arrow-left {
    display: none;
  }
  .arrow-right {
    display: inherit;
  }
}

.bulk-action-bar-wrapper {
  height: 30px;
  position: relative;

  .arrows-holder {
    position: absolute;

    .collapse-menu-progress {
      position: relative;
      &.not-default-progress .arrow-left {
        display: none;
      }
      &.default-progress .arrow-right {
        display: none;
      }
    }

    .collapse-menu-assignee {
      position: absolute;
      top: 0px;
      .arrow-right {
        display: none;
      }
      &.collapsed-assignee {
        @extend %collapsed-state;
      }
    }
    .collapse-menu-duration {
      top: 0px;
      position: absolute;
      .arrow-right {
        display: none;
      }
      &.collapsed-duration {
        @extend %collapsed-state;
      }
    }

    .collapse-menu-planned-schedule {
      top: 0px;
      position: absolute;
      .arrow-right {
        display: none;
      }
      &.collapsed-planned-schedule {
        @extend %collapsed-state;
      }
    }

    .collapse-menu-actual-schedule {
      top: 0px;
      position: absolute;
      .arrow-right {
        display: none;
      }
      &.collapsed-actual-schedule {
        @extend %collapsed-state;
      }
    }

    .collapse-menu-comments {
      position: absolute;
      top: 0px;
      .arrow-right {
        display: none;
      }
      &.collapsed-comments {
        @extend %collapsed-state;
      }
    }
  }

  .selection-pop-up {
    position: fixed;
    left: 10px;
    top: 80px;
    width: 150px;
    border: 1px solid get_color('neutral', 0);
    background-color: get_color('neutral', 100);
    z-index: 1;
  }

  .section {
    max-width: 73px;
    min-width: 73px;
    height: 20px;
    align-items: initial;

    border: solid 1px get_color('neutral', 50);
    background-color: get_color('neutral', 100);
    color: get_color('neutral', 50);

    .stroke-icon svg * {
      stroke: get_color('neutral', 50);
    }
    .fill-icon svg * {
      fill: get_color('neutral', 50);
    }

    &.active,
    &:active {
      background-color: get_color('neutral', 50);
      color: get_color('neutral', 100);

      .stroke-icon svg * {
        stroke: get_color('neutral', 100);
      }
      .fill-icon svg * {
        fill: get_color('neutral', 100);
      }
    }
  }
}

.set-name-container {
  position: fixed;
  top: 213px;
  left: 310px;
  height: $name-container-height;
  width: $name-container-width;
  z-index: 1;

  .content {
    height: 100px;
    justify-content: space-between;
    padding-left: 30px;
  }
}
