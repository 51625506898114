@import '~/client/src/shared/theme.module';

$ROW_HEIGHT: 45px;
$ACTIVITIES_SECTION_HEIGHT: 95px;
$DOWN_ARROWHEAD_ICON: '/static/icons/arrow-down.svg';

.hierarchy-configurator {
  .configurator-row {
    max-height: $ROW_HEIGHT;
    min-height: $ROW_HEIGHT;

    &:not(.no-hover):hover {
      background-color: get_color('primary', 88, 0.5);

      .configurator-row-handle {
        opacity: 1;
      }
    }

    &-band-name::after {
      width: 10px;
      height: 18px;
      float: right;
      content: ' ';
      background: url($DOWN_ARROWHEAD_ICON) no-repeat center;
      background-size: 10px 18px;
    }

    &-handle {
      position: absolute;
      opacity: 0;
      transition: opacity 0.2s linear;

      &.index {
        left: 195px;
        opacity: 1;
      }

      &.change-order {
        left: 215px;

        svg:hover {
          fill: get_color('primary', 30);
        }
      }

      &.deletion {
        right: 420px;

        svg:hover {
          fill: get_color('primary', 30);
        }
      }

      &.add {
        left: 120px;
        opacity: 1;

        svg {
          fill: get_color('primary', 30);
        }
      }

      svg {
        fill: get_color('neutral', 50);
      }
    }
  }

  .activities-section {
    min-height: $ACTIVITIES_SECTION_HEIGHT;
    max-height: $ACTIVITIES_SECTION_HEIGHT;
  }
}
