@import '~/client/src/shared/theme.module';

.permit-settings .form-types-list-page {
  .header-bar {
    padding: 0px !important;
    &.main {
      min-height: 56px !important;
      & > div {
        padding-top: 0px !important;
      }
    }
  }

  .form-types-list {
    .category-collapse-icon,
    .category-collapse-icon svg {
      height: 12px;
      width: 12px;

      path {
        fill: get_color('neutral', 30) !important;
      }
    }

    .select-button {
      border-color: get_color('neutral', 50) !important;
      svg {
        fill: get_color('neutral', 50) !important;
      }
    }
  }

  .filter-container {
    height: 350px !important;
  }
}
