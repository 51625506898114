@import '~/client/src/shared/theme.module';

.delivery-bulk-editor {
  .add-field {
    height: 30px;
    max-width: 200px;

    svg {
      color: get_color('primary', 30);
    }

    &:hover {
      background-color: get_color('grey', 96);
    }
  }

  .dimmer {
    z-index: 21 !important;
  }

  @mixin badge {
    position: absolute;
    top: 5px;

    height: 16px;
    width: 16px;

    border-radius: 50%;
    background-color: get_color('primary', 30);

    color: get_color('neutral', 100);
  }

  .index-label {
    @include badge;
    left: 5px;
  }

  .remove-field {
    @include badge;
    right: 5px;
  }
}
