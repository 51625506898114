$dialog-height: 700px;
$dialog-width: 400px;

.restricted-locations-dialog {
  max-height: $dialog-height;
  min-height: $dialog-height;

  max-width: $dialog-width;
  min-width: $dialog-width;

  .search-input {
    height: 30px;
  }
}
