@import '~/client/src/shared/theme.module';

.announcement-creation-form-container {
  position: absolute;
  z-index: 101;
  background-color: get_color('neutral', 100);

  .cross-icon {
    display: inline-flex;

    svg path:last-child {
      fill: get_color('neutral', 60);
    }
  }

  .announcement-creation-form-content {
    height: calc(100% - 40px);
  }
}
