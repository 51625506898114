@import '~/client/src/shared/theme.module';

.saved-filters-action-bar-wrapper {
  height: 30px;

  .section {
    max-width: 73px;
    min-width: 73px;
    height: 20px;
    align-items: initial;

    &.disabled {
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-image: none;
      color: get_color('neutral', 84);
      border: solid 1px get_color('neutral', 84);
    }

    &.active,
    &:active {
      background-color: get_color('primary', 30);
      color: get_color('neutral', 100);

      svg * {
        fill: get_color('neutral', 100);
        stroke: get_color('neutral', 100);
      }
    }
  }

  .save-section {
    justify-content: flex-end;

    &.disabled {
      color: get_color('neutral', 84);
    }
  }
}
