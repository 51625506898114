@import '~/client/src/shared/theme.module';

$date-picker-options-width: 150px;

.report-side-view {
  right: 0;
  top: 0;
  width: 400px;
  border-left: 1px solid get_color('neutral', 84);
  z-index: 100;
}

.reports-header {
  .date-picker-options-holder {
    top: 40px;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $date-picker-options-width;
    box-shadow: 0 14px 28px get_color('neutral', 0, 0.25),
      0 10px 10px get_color('neutral', 0, 0.25);

    .option {
      width: 100%;
      height: 32px;

      &:hover {
        background-color: get_color('primary', 96);
      }
      &.active {
        color: get_color('neutral', 100);
        background-color: get_color('primary', 30);
      }
    }
  }
  .grouping-options-container {
    max-height: calc(100vh - 270px);
  }
}

.reports-list-container {
  .table-holder {
    align-items: start;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;

    .location-cell-value:hover {
      color: get_color('primary', 50);
    }
  }

  .selection-pop-up {
    z-index: 11;
  }
}

.report-forms-locations-modal .report-forms-locations-content {
  max-height: 50vh;
  max-width: 250px;
}
