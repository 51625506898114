@import '~/client/src/shared/theme.module';

.project-setup-mainframe > .delivery-cards-setup {
  .delivery-cards-section {
    min-width: 315px;
  }

  .delivery-card-preview {
    max-width: 747px;
    flex-grow: 1.5;

    .calendar-preview-title,
    .map-preview-title {
      top: 0;
      z-index: 10;
    }

    .calendar-preview-column {
      width: 310px;
    }
    .map-preview-column {
      height: 100vh;
      min-width: 360px;
    }

    .hour-labels-container {
      flex: 1 1 65px;
    }
  }

  .card-fields-configuration {
    .property-header {
      min-width: 190px;
    }

    .card-config-row {
      height: 32px;

      .config-field-name {
        display: flex;
      }
    }
  }
}

.card-vertical-dots,
.card-vertical-dots svg {
  height: 20px !important;
  width: 14px !important;
  path {
    fill: get_color('neutral', 30) !important;
  }
}
