@import '~/client/src/shared/theme.module';

$nav-bar-height: 48px;
$notification-icon-size: 23px;

.settings {
  min-width: 140px;
  z-index: 999;
  position: absolute;
  right: 15px;
  margin-top: 2px;
  background-color: get_color('neutral', 100);

  &-item {
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;

    &:hover {
      background-color: get_color('blue', 88);
    }

    .option {
      color: get_color('neutral', 10) !important;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.project-creation-step {
  padding: 6px 16px;
  height: 32px;
  border-radius: 4px;
  &.active {
    background-color: get_color('primary', 92);
    color: get_color('primary', 50);
  }
}

.project-creation-vertical-line {
  min-width: 1px;
  height: 32px;

  background: get_color('neutral', 84);

  flex: none;
}

.settings-options {
  right: 30px;
  position: absolute;
  top: 34px;
}

.desktop-nav-bar {
  flex-wrap: wrap;
  box-shadow: 0px 2px 4px 0px get_color('neutral', 0, 0.15);

  .menu-toggler {
    display: none;

    &-icon {
      display: none;
    }
  }
}

.navbar-button {
  .submit-button-container {
    justify-content: flex-end;
  }
}

.company-name {
  font-size: 20px;
  font-weight: 600;
  color: get_color('neutral', 50);
  padding: 0 20px 0 9px;
  cursor: pointer;
  max-width: 300px;
}

.primary-blue-switch {
  .bp3-control-indicator {
    margin: 0;
  }
}

.badge-container {
  position: relative;

  .badge {
    color: get_color('neutral', 100) !important;
    background: get_color('error', 50);
    border: 1px solid get_color('neutral', 100);
    border-radius: 8px;

    min-width: 16px;
    max-height: 16px;

    padding: 0 4px;
    top: -25%;
    left: 30%;
  }
}

.navbar-item {
  line-height: $nav-bar-height;
  height: $nav-bar-height;
  font-size: 14px;
  text-align: center;
  color: get_color('neutral', 30);
  cursor: pointer;
  border-bottom: 1px solid get_color('neutral', 100);

  .bp3-control-indicator {
    margin: 0;
  }

  &:hover {
    color: get_color('secondary', 40);
  }

  &.nohover:hover {
    color: inherit;
    border-bottom-color: get_color('neutral', 100);
  }

  .icon-wrapper svg {
    width: $notification-icon-size;
    fill: get_color('neutral', 60);
  }

  &:not(.nohover).active {
    font-weight: 600;
    color: get_color('secondary', 40);
    svg {
      rect {
        fill: get_color('secondary', 40);
      }
    }

    .icon-wrapper svg {
      fill: get_color('secondary', 40);
    }
  }

  &.notification {
    svg {
      width: $notification-icon-size;
      height: $notification-icon-size;
    }
    &:hover {
      rect {
        fill: get_color('primary', 30);
      }
    }
  }
}

.navbar-list-left {
  max-width: 700px;
}

#settings-item {
  font-weight: 600;
}

.stop-presentation-confirm {
  left: unset;
  right: 50px;
}

@media screen and (max-width: 1200px) {
  .desktop-nav-bar {
    .menu-links {
      display: none;
    }

    .menu-toggler {
      position: absolute;
      right: 23px;
      top: 25px;
      width: 30px;
      height: 30px;
      opacity: 0;
      display: inline-block;

      &-icon {
        display: flex;

        path {
          fill: get_color('primary', 30);
        }
      }

      &:checked + .menu-links {
        display: flex;
      }
    }
  }
}
