@import '~/client/src/shared/theme.module';

.filter-footer {
  height: $FILTER_FOOTER_HEIGHT;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 1px;

  &-btn-cancel {
    position: absolute;
    bottom: 20px;
    left: 30px;
    color: get_color('primary', 30);
    font-size: 16px;
    cursor: pointer;
  }

  &-btn-apply {
    position: absolute;
    bottom: 15px;
    right: 10px;

    width: 160px;
    height: 32px;
    border-radius: 3px;
    background-color: get_color('primary', 30);
    color: get_color('neutral', 100);
    border: none;
    cursor: pointer;

    &.inactive {
      background-color: get_color('neutral', 100);
      border: solid 1px get_color('neutral', 84);
      color: get_color('neutral', 50);
    }
  }
}
