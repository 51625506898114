@import '~/client/src/shared/theme.module';

.action-handle-button {
  height: 32px;
  background-color: get_color('neutral', 100);

  &.enabled {
    background-color: get_color('primary', 30);
    color: get_color('neutral', 100);
  }

  &:hover {
    border-color: get_color('primary', 30);
    background-color: get_color('neutral', 100);
    color: get_color('primary', 30);
  }
}
