@import '~/client/src/shared/theme.module';

.project-setup-mainframe .tags-directory {
  %base-tags-column {
    min-width: 200px;
    max-width: 400px;
    flex-grow: 1;
    display: flex;
  }

  %base-members-column {
    min-width: 350px;
    flex-grow: 2;
    display: flex;
  }

  .tags-directory-tab {
    height: 32px;
    .tag-tab-icon {
      height: 16px;
      width: 16px;

      svg g path {
        fill: get_color('neutral', 0);
      }
    }
  }

  .tags-directory-list-header .tag-header-column {
    @extend %base-tags-column;
  }
  .tags-directory-list-header .members-header-column {
    @extend %base-members-column;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  .ReactVirtualized__Grid.ReactVirtualized__List.tags-directory-list {
    padding: 0 0 10px 30px;
    overflow: auto !important;

    .delete-tag-icon-container {
      left: -25px;
    }

    .tags-directory-tag-column,
    .amount-members {
      height: 48px;
    }
    .team-row {
      height: 300px !important;
    }
    .tags-directory-tag-column {
      @extend %base-tags-column;

      .editable-tag-field {
        display: flex;
      }
    }

    .tags-directory-members-column {
      @extend %base-members-column;

      .member-avatar {
        border: 1px solid get_color('neutral', 100);
        padding: 0;

        &.bp3-button {
          outline: none !important;
          box-shadow: none !important;
          background-image: none;
        }

        &.avatar-container {
          background-color: get_color('grey', 88);

          &.initials-wrapper {
            background: get_color('secondary', 88) !important;
          }
          &.small {
            width: 34px;
            height: 34px;
          }
        }

        &.bp3-active,
        &:hover {
          border-color: get_color('primary', 50);
        }
      }

      .tag-directory-band-users-row {
        min-height: 135px;
      }

      .user-card {
        .modal {
          min-width: 200px;
          max-width: 300px;
          min-height: 100px;
          max-height: 250px;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: get_color('neutral', 96);
          }
        }

        .action-selector {
          top: 30px;
          left: 10px;
          z-index: 2;

          .user-icon,
          .user-icon svg {
            width: 16px;
            height: 16px;
            fill: get_color('neutral', 50);
          }
        }

        .action-btn {
          top: 10px;
          right: 4px;
          transform: rotate(90deg);

          &.selected,
          &:hover {
            background-color: get_color('primary', 92);
          }
        }
      }

      .members-list-selector {
        left: 0;
        top: 0;
        z-index: 100;
        min-width: 375px;
        max-width: calc(100% - 20px);
        max-height: 400px;

        .search-bar-container {
          background: get_color('neutral', 100) !important;
          padding: 5px !important;
        }

        .users-group-by {
          top: 55px;
          right: 5px;
          z-index: 4;
        }
      }
    }
  }
}
