@import '~/client/src/shared/theme.module';

$dot-size: 8px;

.dot-indicator {
  height: $dot-size;
  width: $dot-size;

  &.active {
    background-color: get_color('secondary', 40);
  }
}
