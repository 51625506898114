@import '~/client/src/shared/theme.module';

.workflow-diagram-wrapper {
  width: fit-content;

  .workflow-diagram {
    display: flex;
    justify-content: center;
    color: get_color('neutral', 30);
    border: 1px solid get_color('neutral', 84);

    .change-line {
      border-top: 2px solid;
      height: 15px;

      &.last-step {
        border-top-right-radius: 6px;
      }
    }

    .vertical-line {
      height: 18px;
      border-left: 2px solid;
    }

    .vertical-long-line {
      height: 65px;
      border-left: 2px solid;
    }

    .line {
      width: 18px;
      border-bottom: 2px solid;
    }

    .vertical-arrow {
      height: 11px;
    }

    .arrow {
      width: 11px;

      &-left {
        width: 11px;
        transform: scale(-1, 1);
      }
    }
  }

  .termination-state {
    z-index: 3;
    border-radius: 16px;
    letter-spacing: 0.15px;
  }

  .intermediate-state {
    z-index: 3;
    background: get_color('grey', 90);
    border-radius: 16px;
    letter-spacing: 0.15px;
    color: get_color('neutral', 30);
  }

  .action-state {
    z-index: 3;
    border: 1px solid get_color('primary', 50);
    box-sizing: border-box;
    letter-spacing: 1px;
  }

  .ball-in-court-state {
    z-index: 3;
    width: 20px;
    height: 20px;
    background-color: get_color('error', 50);
    border: 1px solid get_color('neutral', 30);
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(45deg);

    > span {
      transform: rotate(-45deg);
      letter-spacing: 0.35px;

      .bic-state-icon,
      .bic-state-icon svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}
