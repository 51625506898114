@import '~/client/src/shared/theme.module';

.companies-list-container {
  overflow: auto hidden;

  .ReactVirtualized__Table {
    max-width: fit-content;
    margin: auto;

    &__row {
      &:hover {
        background-color: get_color('primary', 92, 0.5);
      }
    }

    &__rowColumn {
      height: 100%;
      margin: 0 !important;
    }
  }

  .company-name-cell {
    .edit-icon {
      display: none;
    }

    &:hover .edit-icon {
      display: block;
    }
  }
}
