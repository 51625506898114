@import '~/client/src/shared/theme.module';

$scrollbar-width: 4px;
$scroll-resizer-width: 15px;
$transition-time: 0.2s;
$move-to-activity-holder-size: 15px;
$move-to-activity-arrow-offset: 4px;
$activity-status-header-expanded: 160px;
$no-percent-started-day-size: 7px;
$no-percent-started-day-offset: 15px;
$header-height: 52px;
$company-bubble-width: 70px;
$checkbox-size: 16px;
$menu-box-shadow: 0 14px 28px get_color('neutral', 0),
  0 10px 10px get_color('neutral', 0);
$manu-option-height: 50px;

:root {
  --day-min-width: 21px;
  --day-width: 32px;
  --activities-min-width: 755px;
  --activities-min-width-short: 595px;
  --left-nav-width: 50px;
}

div {
  box-sizing: border-box;
}

.gantt-cell {
  width: var(--day-width);
  min-width: var(--day-min-width);
}

.gantt-row {
  height: 30px;
  &.activity-row {
    height: $header-height;
  }
  &-header {
    height: 53px;
  }
}

.gant-activity-col {
  display: flex;
  flex: 1;
}

.activity-info-cell {
  cursor: pointer;

  &:hover {
    background-color: get_color('grey', 90);

    .gantt-row {
      background-color: inherit;
    }
  }
}

.gant-activity-icons {
  .icon-critical {
    width: 51px;
  }
}

.selectable-option-holder {
  &.open {
    background-color: get_color('primary', 96);
    box-shadow: $menu-box-shadow;
  }
  &.cov {
    .options-list {
      width: 300px;
    }
  }
  &.on-time {
    .options-list {
      width: 160px;
    }
  }
  .options-list {
    box-shadow: $menu-box-shadow;
    top: -25px;
    z-index: 2;
    .option {
      &:hover {
        background-color: get_color('primary', 96);
      }
      height: $manu-option-height;
    }
    .seach-input {
      height: $manu-option-height;
    }
  }
}

.wbs-caption {
  font-size: 14px;
  color: get_color('primary', 30);
}

.row {
  display: flex;
  flex-direction: row;
}

.row.x-end {
  justify-content: flex-end;
}

.row.y-center {
  align-items: center;
}

.gant-selection {
  background-color: get_color('primary', 96);

  .gantt-row {
    background-color: inherit !important;
  }
}

.gant-chart-col {
  border-bottom: var(--border-grey);
  display: flex;
  flex: 1;
}

.gant-header-day {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 52px;
  border-right: 1px solid get_color('grey', 92);
  border-bottom: 1px solid get_color('grey', 92);
  font-size: 12px;
  box-sizing: content-box;
  &-circle {
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    right: calc(50% - 4.5px);
    color: gold;
  }
}

.gant-day {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: get_color('neutral', 100);
  border-right: 1px solid get_color('grey', 92);
  overflow-x: visible;
  flex-grow: 1;
  box-sizing: content-box;
  * {
    flex-grow: 1;
  }
}

.gant-day.line {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 2px dashed gold;
  border-right: none;
  background-color: transparent;
  height: 85%;
}

.gant-day.weekend.gant-selection,
.gant-day.gant-selection {
  background-color: get_color('primary', 88, 0.5);
}

.gant-header-day.weekend,
.gant-day.weekend {
  background: get_color('neutral', 96);
}

.gant-activity-bar {
  height: 15px;
  border-top: 1px solid get_color('primary', 30);
  border-bottom: 1px solid get_color('primary', 30);
  z-index: 5;
  color: get_color('neutral', 100);
  width: calc(100% + 1px);
  &-bp-caption {
    padding-top: 1px;
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    padding-left: 4px;
    font-weight: 600;
    font-size: 14px;
    color: get_color('neutral', 70);
    z-index: 10;
  }
  &.inactive {
    border-color: get_color('neutral', 50) !important;
  }
  &.cp {
    border-color: get_color('error', 50) !important;
  }
  &.start-day {
    border-top-left-radius: 2px;
    border-left: 1px solid get_color('primary', 30);
  }
  &.end-day {
    border-top-right-radius: 2px;
    border-right: 1px solid get_color('primary', 30);
  }
  &.completed-day {
    background: get_color('primary', 30);
  }

  .half-completed-day {
    position: absolute;
    top: 20px;
    left: 0;
    height: 15px;
    border-top-left-radius: 2px;
    background: get_color('primary', 30);
    &.no-percent {
      width: $no-percent-started-day-offset;
    }
  }

  .no-percent-started-day {
    position: absolute;
    left: $no-percent-started-day-offset;
    border-top: $no-percent-started-day-size solid transparent;
    border-bottom: $no-percent-started-day-size solid transparent;
    border-left: $no-percent-started-day-size solid get_color('primary', 30);
  }
  .holiday-icon {
    color: get_color('neutral', 70);
    position: absolute;
    left: calc(50% - 6px);
    top: 7px;

    svg {
      height: 12px;
      width: 12px;
    }
  }
}

.gant-planned-bar {
  height: 7px;
  background: get_color('neutral', 50);
  width: calc(100% + 1px);
  &.cp {
    background: get_color('error', 50);
  }
  &.start-day {
    border-bottom-left-radius: 2px;
  }
  &.end-day {
    border-bottom-right-radius: 2px;
  }
  &.end-day-wwp-coloring {
    background: get_color('error', 50);
  }
  &.default-wwp-coloring {
    background: get_color('success', 88);
  }
  &.day-before-finish-wwp-coloring {
    background: get_color('warning', 40);
  }
}

.flex-item {
  flex: 1;
}

.text-bold {
  font-weight: 600;
}

.resize-cursor-holder {
  height: $header-height;
  width: 10px;
  position: absolute;
  z-index: 1;
  background-color: transparent;
  top: 0;
  &:hover {
    background-color: get_color('primary', 70);
    opacity: 0.3;
  }
  &:active {
    background-color: get_color('primary', 70);
    opacity: 0.3;
  }
}

.gant-id-col,
.gant-percent-col {
  flex-basis: 90px;
  flex-grow: 0;
}

.scrollable-gantt-cell {
  overflow-x: overlay;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: $scroll-resizer-width;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: get_color('primary', 30);
  }
}
.gant-chart {
  &-container {
    display: flex;
    height: 100%;
  }
  &-content {
    -webkit-transform: translate3d(0, 0, 0);
    width: 100%;
    height: 100%;
    overflow: auto hidden;

    $activity-col-index: 1;
    $days-col-index: 2;

    .ReactVirtualized__Table__headerRow {
      font-weight: 400;
      text-transform: none;
    }
    .done-on-time {
      background-color: get_color('success', 88);
    }
    .done-not-on-time {
      background-color: get_color('error', 88);
    }

    &.default-progress {
      .ReactVirtualized {
        &__Table {
          .activity-status-header {
            transition: all $transition-time;
          }
          .activity-status-header,
          .manpower-complete-coll {
            .rotated-text {
              display: none;
            }
          }
        }
      }
    }
    &.not-default-progress {
      %col-styles {
        &:nth-child(#{$activity-col-index}) {
          max-width: var(--activities-min-width-short);
          width: var(--activities-min-width-short);
          flex: 15 0 var(--activities-min-width-short) !important;
        }
      }
      .ReactVirtualized {
        &__Table {
          .activity-status-header {
            transition: all $transition-time;
            &.hideable {
              max-width: 0;
              min-width: 0;
              width: 0;
            }
          }
          .activity-status-header,
          .manpower-complete-coll {
            .rotated-text {
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              transform: rotate(-90deg);
              justify-content: center;
            }
            max-width: $COLLAPSED_SECTION_WIDTH;
            min-width: $COLLAPSED_SECTION_WIDTH;
            div {
              display: none;
            }
          }
          min-width: calc(
            var(--activities-min-width-short) + var(--left-nav-width) + 21 *
              var(--day-width)
          );
        }
      }
    }

    .gantt-header-container {
      &.stretched {
        min-width: 100%;
      }
      flex: 0 0 83px;
    }
    .row {
      &.bulk-action-row {
        position: relative;

        .bulk-action-bar-container {
          position: absolute;
          left: 10px;
          top: 0;
        }

        .gant-activity-col:nth-child(#{$activity-col-index}) {
          height: 30px;
          opacity: 0.3;
        }

        .gant-activity-col:nth-child(#{$days-col-index}) {
          opacity: 0.3;
        }
      }

      &.header-row {
        flex: 0 0 53px;

        .sorting {
          background-color: get_color('neutral', 0);
          width: 100%;
          color: get_color('neutral', 100);

          div:first-child {
            max-width: calc(100% - 16px);
            color: get_color('neutral', 100);
          }

          .sorting-arrow-icon {
            path {
              fill: get_color('neutral', 100);
            }
          }
        }
      }
    }
    .ReactVirtualized__Table__rowColumn {
      margin: 0;
      &:first-of-type {
        margin-left: 0;
      }
    }

    %col-styles {
      &:nth-child(#{$activity-col-index}) {
        height: 100%;
      }
    }
    .ReactVirtualized {
      &__Table {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: calc(
          var(--activities-min-width) + var(--left-nav-width) + 21 *
            var(--day-width)
        );
        &__Grid {
          width: 100% !important;
          outline: none;
          overflow-y: scroll !important;

          &::-webkit-scrollbar {
            width: $scroll-resizer-width;
            height: $scroll-resizer-width;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: get_color('primary', 30);
          }
        }
        &__headerRow {
          width: 100% !important;
          height: 83px !important;
        }
        &__row {
          width: 100% !important;
          max-width: unset !important;
          padding-right: 0 !important;
        }
        &__headerColumn {
          @extend %col-styles;
          margin: 0 !important;
          outline: none;
        }
        &__rowColumn {
          @extend %col-styles;
        }
      }
      &__Grid__innerScrollContainer {
        width: 100% !important;
        max-width: unset !important;
      }
    }
  }
}

.gantt-container {
  height: 100%;
  overflow-y: hidden;
  width: 100%;
}

.gant-left-navigation {
  svg path {
    fill: get_color('neutral', 40);
  }
  max-width: var(--left-nav-width);
  min-width: var(--left-nav-width);
}

.company-bubble {
  min-width: $company-bubble-width;
  max-width: $company-bubble-width;
  position: relative;
  &:hover {
    .companies-list-holder {
      visibility: visible;
    }
  }
  .company-label {
    padding: 0 2px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    text-overflow: ellipsis;

    &.noname {
      border-radius: 12px;
      background-color: get_color('purple', 96);
      padding: 0 5px;
    }
  }
  .companies-list {
    position: fixed;
    z-index: 4;
    &-holder {
      width: 135px;
      z-index: 10;
      display: flex;
      flex-direction: column;
      background-color: get_color('neutral', 100);
      visibility: hidden;
      position: absolute;
      &-company-label {
        text-align: center;
      }
    }
  }
}

.no-start-activity {
  flex-grow: 0;
  color: get_color('grey', 90);
}

.activity-name-header {
  font-size: 12px;

  .activity-id-caption {
    max-width: 140px;
    min-width: 140px;
  }

  .activity-company-caption {
    max-width: 80px;
  }
}

.gant-left-coll-cell {
  display: flex;
  flex-direction: row;
  height: 100%;

  .name-coll {
    overflow: hidden;
    flex-grow: 1;
  }

  .empty-coll {
    height: $header-height;
    min-width: 160px;
    padding-right: 5px !important;
    justify-content: space-around;
  }
}

.colored-val {
  border-radius: 7px;
  padding: 0 7px;
  margin-left: 5px;
  flex-grow: 0;

  &.blue {
    color: get_color('neutral', 100);
    background-color: get_color('primary', 30);
  }

  &.green {
    color: get_color('neutral', 0);
    background-color: get_color('success', 80);
  }

  &.yellow {
    color: get_color('neutral', 0, 1);
    background-color: get_color('secondary', 60);
  }

  &.red {
    color: get_color('neutral', 100);
    background-color: get_color('error', 50);
  }
}
.move-to-activity-holder {
  width: $move-to-activity-holder-size;
  height: $move-to-activity-holder-size;
  position: absolute;
  &.right {
    right: 0;
  }
  &.left {
    left: 0;
  }
  .arrow {
    border-width: 0 1px 1px 0 !important;
    display: inline-block;
    padding: 3px;
    position: absolute;
    top: $move-to-activity-arrow-offset;
    &.right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      right: $move-to-activity-arrow-offset;
    }
    &.left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      left: $move-to-activity-arrow-offset;
    }
    &.blue {
      border: solid get_color('primary', 30);
    }
    &.white {
      border: solid get_color('neutral', 100);
    }
  }
}

.days-company-label {
  position: absolute;
  z-index: 1;
  left: 5px;
  &.top {
    top: 2px;
  }
}

.activity-name-label {
  position: absolute;
  top: 20px;
  left: calc(100% + 10px);
  color: black;
  z-index: 1;
}

.checkbox-container {
  max-width: 29px;
  min-width: 29px;
  .p-checkbox-box {
    background-color: get_color('neutral', 100);
    border-color: get_color('neutral', 84);
    width: $checkbox-size !important;
    height: $checkbox-size !important;
  }
}
.wbs-text {
  width: 565px;
  .wbs-row {
    display: flex;
    justify-content: flex-end;

    svg path {
      fill: get_color('primary', 30);
    }
    .dark {
      svg path {
        fill: get_color('neutral', 0);
      }
    }
    .white {
      svg path {
        fill: get_color('neutral', 100);
      }
    }
  }
}
