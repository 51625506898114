@import '~/client/src/shared/theme.module';

.schedule-history {
  &-section {
    margin-top: 45px;

    &-header {
      padding-bottom: 12px;
      font-weight: 600;
    }

    &-lines-container,
    .line-container {
      padding-left: 20px;

      &.history-line,
      .history-line {
        color: get_color('neutral', 50);
        margin-top: 20px;

        &:first-child,
        &.line {
          margin-top: 0;
        }

        .single-line {
          flex: none;
        }

        &.history-line-info,
        .history-line-info {
          width: 90%;
        }

        .title {
          font-weight: 600;
        }

        .history-line-restore {
          text-align: right;

          .restore-btn {
            border: none;
            outline: none;
            cursor: pointer;
            color: get_color('primary', 30);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
