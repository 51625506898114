@import '~/client/src/shared/theme.module';

.date-format-select {
  &-options {
    left: 0;
    top: 37px;
    min-height: 20px;
    box-shadow: 0 0 4px get_color('neutral', 84);

    &.open {
      display: block !important;
    }
  }

  &-option {
    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    &-selected,
    &:hover {
      background-color: get_color('primary', 70);
      color: get_color('neutral', 100);
    }
  }
}
