@import '~/client/src/shared/theme.module';

.activity-bulk-container {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-left: 1px solid get_color('neutral', 84);
  width: 400px;
  background-color: get_color('neutral', 100);
  z-index: 98;
}

.activity-bulk-editor {
  .add-field {
    height: 30px;
    max-width: 200px;

    svg {
      color: get_color('primary', 30);
    }

    &:hover {
      background-color: get_color('grey', 96);
    }
  }

  @mixin badge {
    position: absolute;
    top: 5px;

    height: 16px;
    width: 16px;

    border-radius: 50%;
    background-color: get_color('primary', 30);

    color: get_color('neutral', 100);
  }

  .index-label {
    @include badge;
    left: 5px;
  }

  .remove-field {
    @include badge;
    right: 5px;
  }
}
