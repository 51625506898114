@import '~/client/src/shared/theme.module';

.responsible-toggler-container {
  .responsible-icon svg g {
    fill: get_color('neutral', 50);
  }

  .unresponsible-icon svg g {
    stroke: get_color('neutral', 50);
  }

  &.editing {
    .responsible-icon svg g {
      fill: get_color('primary', 50);
    }

    .unresponsible-icon svg g {
      stroke: get_color('primary', 50);
    }
  }

  .members-list {
    max-height: 400px;
  }
}
