@import '~/client/src/shared/theme.module';

.filter-container {
  &.location {
    left: 0;
    top: calc(100% + 10px);
  }

  @media screen and (max-width: $SMALL-SCREEN-WIDTH) {
    left: 10px;
  }

  &-background {
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    background-color: get_color('neutral', 100);
    opacity: 0.8;
  }
}
