@import '~/client/src/shared/theme.module';

.notification-rules-wrapper {
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  .ReactVirtualized__Grid.ReactVirtualized__List.notification-list {
    padding: 10px 10px 35px 25px;
    overflow: auto !important;
  }
}
