@import '~/client/src/shared/theme.module';

$button-size: 30px;
$button-offset: 20px;
.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: get_color('neutral', 100, 0.7);
  align-items: center;

  &.qr-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &.bordered {
    padding: 40px 90px 50px 130px;
    background-color: get_color('neutral', 100);
  }
  &-preview {
    object-fit: contain;
  }
  &-button {
    position: absolute;
    width: $button-size;
    height: $button-size;
    top: $button-offset;
    right: $button-offset;
  }

  .photo-with-comment {
    height: 80%;
  }
}
