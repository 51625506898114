@import '~/client/src/shared/theme.module';

$banner-title-width: 220px;
$change-action-line-width: 45px;

.workflows-setup-main {
  .top-level {
    width: 100%;
    align-items: flex-start;
    .top-line {
      position: absolute;
      max-width: $change-action-line-width;
      min-width: $change-action-line-width;
      height: 50px;
      border-radius: 10px;
      border-top: 1px solid;
      margin-top: 15px;
      &.right {
        border-right: 1px solid;
        margin-left: 215px;
      }
      &.left {
        border-left: 1px solid;
        margin-left: 55px;
      }
    }
    .arrow {
      position: absolute;
      &.right {
        margin-left: 220px;
        margin-top: 6px;
        transform: rotate(180deg);
      }
      &.left {
        margin-left: 48px;
        margin-top: 40px;
        transform: rotate(90deg);
      }
    }
  }
  .bottom-level {
    max-width: 900px;
  }
  .banner {
    width: fit-content;
    height: 156px;
    padding: 0 25px;
    display: flex;
    background-color: get_color('primary', 10);
    color: get_color('neutral', 100);
    &-title {
      max-width: $banner-title-width;
      min-width: $banner-title-width;
    }
    &-header {
      padding: 20px 0;
      color: get_color('warning', 70);
    }
    .line {
      font-weight: 600;
      width: 10px;
      height: 11px;
      border-bottom: 2px solid get_color('neutral', 100);
    }
    &-description {
      min-width: 160px;
    }

    &-chart {
      display: flex;
      justify-content: center;
      align-items: center;

      & > * {
        padding: 10px;
      }

      &-text {
        color: get_color('warning', 70);
        white-space: nowrap;
      }
      &-next-step {
        display: flex;
        padding: 0;
        .arrow {
          margin-left: -5px;
          margin-top: 2px;
          margin-right: -5px;
        }
      }
      &-rectangle {
        &.top-level {
          margin-left: 90px;
        }
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        max-width: 135px;
        min-width: 115px;
        border-radius: 3px;
        text-align: center;
        padding: 0;

        color: get_color('primary', 20);
        background-color: get_color('primary', 88);
      }
    }
  }
}
