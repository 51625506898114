@import '~/client/src/shared/theme.module';

$default-icon-size: 16px;
$large-icon-size: 20px;
$default-icon-color: get_color('neutral', 0);
$primary-color: get_color('primary', 50);

.desktop-materials-view {
  .permit-side-view {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 400px;
    border-left: 1px solid get_color('neutral', 84);
    background-color: get_color('neutral', 100);
    z-index: 100;
  }

  .multi-grid-container .cell.category-cell svg {
    color: unset;
  }
  .desktop-deliveries-details {
    z-index: 100;
  }

  .form-cell-add-icon,
  .form-cell-add-icon svg {
    width: $default-icon-size;
    height: $default-icon-size;

    path:last-child {
      fill: $default-icon-color;
      stroke: $default-icon-color;
    }
  }

  .form-cell-value:hover,
  .current-location-cell-value:hover {
    color: $primary-color;

    .form-cell-add-icon,
    .form-cell-add-icon svg {
      path:last-child {
        fill: $primary-color;
        stroke: $primary-color;
      }
    }
  }

  .header-bar .material-filters-container {
    flex: 1 !important;

    .filter-header-hint.code {
      max-width: unset !important;
      text-wrap: nowrap;
    }
  }
}

.bp3-popover.form-cell-modal,
.bp3-popover.current-location-cell-modal {
  .form-modal-add-icon,
  .form-modal-add-icon svg {
    width: $default-icon-size;
    height: $default-icon-size;
    path:last-child {
      fill: $primary-color;
      stroke: $primary-color;
    }
  }

  .form-id-icon,
  .form-id-icon svg {
    width: $default-icon-size;
    height: $default-icon-size;
    path:last-child {
      fill: $default-icon-color;
      stroke: $default-icon-color;
    }
  }

  .form-id-icon-large,
  .form-id-icon-large svg {
    width: $large-icon-size;
    height: $large-icon-size;
  }

  .bp3-popover-content .ids-date-values-list {
    max-height: 300px;
  }

  .form-id-link:hover {
    color: $primary-color;
  }

  .ReactVirtualized {
    &__Table {
      display: flex;
      flex-direction: column;

      &__row {
        border-top: 1px solid get_color('neutral', 84);

        &:first-of-type {
          border-top: none !important;
        }
      }

      &__rowColumn {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .delivery-status > .hierarchy-delivery-attribute-tag {
        max-width: 110px;
      }

      .material-location-checkbox .p-checkbox-box.p-component {
        border-color: $primary-color !important;
      }

      .material-location-checkbox.checked .p-checkbox-box.p-component {
        color: get_color('neutral', 100);
        background: $primary-color;
      }

      .workflow-card-status {
        margin: 0 !important;
      }
    }
  }

  .select-button {
    border-color: $primary-color !important;
    color: $primary-color !important;
    .bp3-icon svg {
      fill: $primary-color !important;
    }
  }

  .selection-pop-up {
    position: absolute;
    left: 0;
    top: 35px;
    width: 150px;
    border: 1px solid get_color('slate', 90);
    background-color: get_color('neutral', 100);
    border-radius: 0 4px 4px 4px;
    z-index: 1;
  }
}
