@import '~/client/src/shared/theme.module';

.objects-list .object-row .permitted-companies-container {
  top: 10px;

  background-color: get_color('neutral', 100);
  z-index: 2;

  $overall-modal-height: 400px;
  $footer-height: 40px;

  height: $overall-modal-height;
  width: 350px;

  > .compact-companies-directory {
    height: $overall-modal-height - $footer-height !important;

    .hierarchy-delivery-attribute-tag {
      width: unset !important;
    }
  }

  footer {
    height: $footer-height;

    button {
      height: 100%;
      border: none;
      background-color: get_color('neutral', 100);

      &:first-child {
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        background-color: get_color('primary', 30);
        border-bottom-right-radius: 4px;
      }
    }
  }
}
