@import '~/client/src/shared/theme.module';

.globe-properties-panel {
  .duplicate-input {
    .bare-input-appearance {
      padding-right: 35px !important;
    }
  }
  background-color: get_color('neutral', 100);
  flex-basis: 300px;
  max-width: 300px;
  min-width: 300px;
  border-right: none;
  border: 1px solid get_color('neutral', 60);
  &.globe-panel {
    right: 0;
    z-index: 10;
  }
  &.closed-panel {
    flex-basis: 20px;
    max-width: 20px;
  }
  .base-action-btn {
    background-color: get_color('primary', 60) !important;
    color: get_color('neutral', 100) !important;
    border-color: get_color('neutral', 100) !important;
    &:hover {
      background-color: get_color('neutral', 100) !important;
      color: get_color('primary', 40, 0.08) !important;
      border-color: get_color('primary', 40, 0.08) !important;
    }
  }

  .properties-section {
    border-bottom: 1px solid get_color('neutral', 84);

    .parent-selector-modal {
      height: 70vh !important;
      width: 280px !important;
    }
  }

  .edit-ref-button {
    border: none;
    background-color: get_color('neutral', 100);
  }

  .referenced-icon {
    svg path {
      fill: get_color('neutral', 50);
    }
  }

  .input-right-table {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    height: 40px;
    background: get_color('neutral', 96);
    border: 1px solid get_color('neutral', 92);
    border-radius: 4px;
  }

  .action-step-button {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    color: get_color('neutral', 100);
    background-color: get_color('primary', 40);
    &.reversed {
      border: 1px solid get_color('primary', 0);
      color: get_color('primary', 0);
      background-color: get_color('neutral', 100);
    }
  }

  .title {
    letter-spacing: 0.5px;
    font-weight: 600;
    color: get_color('neutral', 60);
  }

  .select-arrow-down {
    svg {
      width: 12px;
      height: 12px;
      path {
        fill: get_color('neutral', 60);
      }
    }
  }

  .properties-checkbox {
    .p-checkbox {
      margin: 0;

      &-box {
        color: get_color('neutral', 60);
        border: 1px solid get_color('neutral', 60);
      }
    }
  }

  .property-select {
    display: flex;
    height: 32px;
    background-color: get_color('neutral', 96);
    border-radius: 2px;
    align-items: center;

    &.inline-selector {
      width: 84px;
    }

    .bp3-popover-target {
      width: 100%;
    }
  }
}

.config-icon {
  position: absolute;
  right: 0;
  top: 18px;
  font-size: 20px;
  padding: 1px 2px;
  cursor: pointer;
  border-radius: 2px;

  &.active {
    color: get_color('primary', 50);
    background-color: get_color('primary', 96);
    pointer-events: none;
  }
}

.select-ground-width {
  width: 55px;
  flex-basis: 55px;
}

.index-width {
  width: 45px;
  flex-basis: 45px;
}

.level-name-width {
  width: 100px;
  flex-basis: 100px;
}

.code-width {
  width: 54px;
  flex-basis: 54px;
}

.level-row {
  background-color: get_color('neutral', 100);

  .vertical-dots {
    display: inline-flex;

    svg {
      width: 16px;
    }
  }

  .bp3-control.bp3-radio {
    margin: 0 0 0 6px;

    & input:checked ~ .bp3-control-indicator {
      background-color: get_color('neutral', 100);
      box-shadow: inset 0 0 0 1px get_color('primary', 50),
        inset 0 -1px 0 get_color('primary', 50);

      &::before {
        background-image: radial-gradient(
          get_color('primary', 50),
          get_color('primary', 50) 28%,
          transparent 32%
        );
      }
    }
  }
}

.level-naming-options-dialog {
  position: absolute;
  width: 260px;
  background-color: get_color('neutral', 100);
  border-radius: 4px;
  right: 6px;
  top: 16px;
  box-shadow: 0 10px 30px 0 get_color('neutral', 50);
  z-index: 10;

  .bp3-control.bp3-switch.level-naming-options-switch {
    padding: 0;

    .bp3-control-indicator {
      width: 43px;
      height: 28px;
      margin: 0;
      background-color: get_color('neutral', 70);

      &::before {
        width: 26px;
        height: 26px;
        margin: 1px;
      }
    }

    input:checked ~ .bp3-control-indicator {
      background: get_color('primary', 50);

      &::before {
        left: 15px;
      }
    }
  }
}

.building-levels-selector {
  position: absolute;
  max-height: 450px !important;
  width: 260px;
  background-color: get_color('neutral', 100);
  border-radius: 4px;
  bottom: 16px;
  box-shadow: 0 10px 30px 0 get_color('neutral', 50);
  z-index: 10;
}

.object-accessible-levels-properties {
  .cross-icon {
    display: inline-flex;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: get_color('neutral', 50);
    color: get_color('neutral', 100);
    cursor: pointer;
    font-size: 12px;
    justify-content: center;
    align-items: center;
  }
}
