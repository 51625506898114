@import '~/client/src/shared/theme.module';

$activity-status-header-expanded: 160px;
$name-cell-width: 220px;
$date-cell-width: 100px;
$company-cell-width: 100px;
$date-cell-text-width: 80px;
$arrow-box-size: 16px;
$status-height: 25px;
$status-width: 39px;
$header-height: 53px;
$transition-time: 0.2s;
$bulk-action-bar-height: 30px;

$activity-col-index: 1;
$building-col-index: 2;

%common-rotated-section-title {
  top: 11px;
  display: flex;
  align-items: center;
  max-width: 28px;
  height: 30px;
  transform: rotate(-90deg);
}

@mixin collapsed-cell($col-index) {
  .ReactVirtualized__Table__headerColumn:nth-child(#{$col-index}),
  .ReactVirtualized__Table__rowColumn:nth-child(#{$col-index}) {
    @if $col-index != -1 {
      max-width: $COLLAPSED_SECTION_WIDTH !important;
      min-width: $COLLAPSED_SECTION_WIDTH !important;
      border-bottom: 1px solid get_color('neutral', 84);

      .hidable-content,
      .ReactVirtualized__Table__headerTruncatedText {
        display: none;
      }
    }
  }
}

@mixin collapsed-sections($planned-duration-col-index) {
  $remaining-duration-col-index: $planned-duration-col-index + 1;
  $percent-complete-col-index: $remaining-duration-col-index + 1;
  $planned-start-col-index: $percent-complete-col-index + 1;
  $planned-end-col-index: $planned-start-col-index + 1;
  $actual-start-col-index: $planned-end-col-index + 1;
  $actual-end-col-index: $actual-start-col-index + 1;
  $comments-col-index: $actual-end-col-index + 1;

  &.collapsed-assignee {
    .sections-holder .section-title.assignee {
      @extend %common-rotated-section-title;
    }
    @include collapsed-cell($building-col-index);
  }
  &.collapsed-duration {
    .sections-holder .section-title.duration {
      @extend %common-rotated-section-title;
    }
    @include collapsed-cell($planned-duration-col-index);
  }
  &.collapsed-planned-schedule {
    .sections-holder .section-title.planned-duration {
      @extend %common-rotated-section-title;
    }
    @include collapsed-cell($planned-start-col-index);
  }
  &.collapsed-actual-schedule {
    .sections-holder .section-title.actual-duration {
      @extend %common-rotated-section-title;
    }
    @include collapsed-cell($actual-start-col-index);
  }
  &.collapsed-comments {
    .sections-holder .section-title.comments {
      white-space: nowrap;
      @extend %common-rotated-section-title;
    }
    @include collapsed-cell($comments-col-index);
  }

  &.right {
    .bulk-bar-holder {
      position: absolute;
      top: 53px;
      background-color: get_color('neutral', 100);
      z-index: 10;
    }
    .sections-holder {
      position: absolute;
      top: 0;
      z-index: 1;
      .section-title {
        transition: left $transition-time;
        position: absolute;
        top: 5px;
        line-height: 11px;
        letter-spacing: 0.3px;
        color: get_color('primary', 30);
        width: fit-content;
      }
    }

    &.collapsed-progress .ReactVirtualized__Table {
      &__headerColumn:nth-child(#{$activity-col-index}) {
        min-width: auto !important;
        flex: 0 !important;
        .activity-scrollable-header {
          max-width: $COLLAPSED_SECTION_WIDTH !important;
          min-width: $COLLAPSED_SECTION_WIDTH !important;
        }
        .activity-status-header {
          height: $header-height !important;
        }
      }
      &__headerColumn:nth-child(#{$activity-col-index}),
      &__rowColumn:nth-child(#{$activity-col-index}) {
        .activity-status-header {
          &.hideable {
            max-width: 0;
            min-width: 0;
            width: 0;
          }
        }
        .activity-status-header,
        .scrollable-gantt-cell,
        .manpower-complete-coll {
          max-width: $COLLAPSED_SECTION_WIDTH;
          min-width: $COLLAPSED_SECTION_WIDTH;
          width: $COLLAPSED_SECTION_WIDTH;
          .rotated-text {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            transform: rotate(-90deg);
            justify-content: center;
          }
          div {
            display: none;
          }
        }
      }
    }

    .ReactVirtualized__Table {
      &__Grid {
        overflow: hidden auto !important;
      }
      &__headerRow {
        width: 100%;
        align-items: flex-end;
        border-bottom: 1px solid get_color('grey', 84);
        min-height: $header-height;
        max-height: $header-height;
        .activity-name-header {
          height: $header-height;
        }
      }
      &__headerColumn {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-right: 0;
        padding-top: 0;
        &:nth-child(#{$activity-col-index}) {
          .activity-status-header {
            transition: all $transition-time;
          }
        }
        &:nth-child(#{$comments-col-index}) {
          justify-content: flex-start;
          border-right: 1px solid get_color('grey', 84);
        }
      }
      &__rowColumn:nth-child(#{$comments-col-index}) {
        border-right: 1px solid get_color('grey', 84);
        .cell-wrapper {
          justify-content: flex-start;
        }
      }
      &__headerColumn:nth-child(#{$activity-col-index}) {
        transition: all $transition-time !important;
        flex: 0 !important;
        .activity-status-header {
          height: $header-height !important;
        }
      }
      &__headerColumn,
      &__rowColumn {
        &:first-of-type {
          margin-left: 0;
        }
        &:nth-child(#{$activity-col-index}) .activity-status-header {
          height: $header-height !important;
          width: $activity-status-header-expanded;
          max-width: $activity-status-header-expanded;
          min-width: $activity-status-header-expanded;
        }
        &:nth-child(#{$activity-col-index}),
        &:nth-child(#{$building-col-index}),
        &:nth-child(#{$planned-duration-col-index}),
        &:nth-child(#{$planned-start-col-index}),
        &:nth-child(#{$actual-start-col-index}),
        &:nth-child(#{$comments-col-index}) {
          border-left: 1px solid get_color('grey', 84);
          .rotated-text {
            display: none;
          }
        }
      }
    }
  }
  display: flex;
  height: 100%;
  %col-styles {
    text-align: left;
    .cell-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.selected {
        background-color: get_color('primary', 88, 0.5);
      }
      &.no-center {
        justify-content: unset;
      }
      .name-cell {
        width: 100%;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        cursor: pointer;
        &-text {
          max-width: $name-cell-width;
        }
      }
      .status {
        width: $status-width;
        height: $status-height;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: get_color('purple', 96);
      }
      .company-cell {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: $company-cell-width;
      }
      .activity-code-cell {
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: unset;
        .sticky-checkbox {
          position: absolute;
          left: 10px;
        }
      }
      .arrow-box {
        color: get_color('neutral', 50);
        width: $arrow-box-size;
        height: $arrow-box-size;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &:nth-child(#{$planned-start-col-index}),
    &:nth-child(#{$planned-end-col-index}),
    &:nth-child(#{$actual-start-col-index}),
    &:nth-child(#{$actual-end-col-index}) {
      flex: 0 0 100px;
      justify-content: center;
      text-align: center;
      .ReactVirtualized__Table__headerTruncatedText {
        // max-width: $date-cell-text-width;
        text-align: center;
      }
    }
    &:nth-child(#{$planned-duration-col-index}),
    &:nth-child(#{$remaining-duration-col-index}),
    &:nth-child(#{$percent-complete-col-index}),
    &:nth-child(#{$planned-duration-col-index - 1}) {
      .ReactVirtualized__Table__headerTruncatedText {
        white-space: normal !important;
        text-overflow: unset !important;
        text-align: center;
      }
    }
  }
  .ReactVirtualized {
    &__Table {
      display: flex;
      flex-direction: column;
      width: 100%;
      &__Grid {
        width: 100%;
        outline: none;
        margin-top: $bulk-action-bar-height;
      }
      &__row {
        width: 100%;
        max-width: unset !important;
        padding-right: 0 !important;
        overflow: visible !important;
      }
      &__headerColumn {
        @extend %col-styles;
        outline: none;

        .sorting {
          background-color: get_color('neutral', 0);
          width: 100%;
          color: get_color('neutral', 100);

          div:first-child {
            max-width: calc(100% - 16px);
            color: get_color('neutral', 100);
          }

          .sorting-arrow-icon {
            path {
              fill: get_color('neutral', 100);
            }
          }

          .ReactVirtualized__Table__headerTruncatedText {
            color: get_color('neutral', 100);
          }
        }
      }
      &__rowColumn {
        @extend %col-styles;
        justify-content: center;
        margin-right: 0;
        flex: none !important;
        overflow: visible !important;
      }
      &__rowColumn,
      &__headerColumn {
        display: flex;
        align-items: center;
        justify-content: left;
      }
      &__headerTruncatedText {
        padding: 0 5px;
        width: 100%;
        text-align: center;
        color: get_color('neutral', 0);
      }
      &__sortableHeaderColumn {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 5px;
      }
      &__headerRow {
        position: sticky;
        top: 0;
        background-color: get_color('neutral', 100);
      }
    }
    &__Grid__innerScrollContainer {
      width: 100% !important;
      max-width: unset !important;
    }
  }
}

.days-cell {
  left: 750px;
}
.activity-list-container {
  height: 100%;
  .tables-holder {
    align-items: start;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
    .table {
      &.tags-count-1 {
        $planned-duration-col-index: $building-col-index + 1;

        @include collapsed-sections($planned-duration-col-index);
      }
      &.tags-count-2 {
        $planned-duration-col-index: $building-col-index + 2;

        @include collapsed-sections($planned-duration-col-index);
      }
      &.tags-count-3 {
        $planned-duration-col-index: $building-col-index + 3;

        @include collapsed-sections($planned-duration-col-index);
      }
      &.tags-count-4 {
        $planned-duration-col-index: $building-col-index + 4;

        @include collapsed-sections($planned-duration-col-index);
      }
      &.tags-count-5 {
        $planned-duration-col-index: $building-col-index + 5;

        @include collapsed-sections($planned-duration-col-index);
      }
    }
  }
}

.missing-tag-dialog {
  .tag-dialog-main {
    width: 400px;
    height: calc(100vh - 250px);
    max-height: 350px;
  }
  .tag-dialog-body {
    height: calc(100% - 125px);
  }
}

.message-status-unread {
  min-width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 100%;
  background: get_color('primary', 40);
}

.comment {
  display: flex;
  align-items: center;
}
