@import '~/client/src/shared/theme.module';

.thresholds-setup {
  .small-col {
    flex-grow: unset;
    flex-basis: 90px;
  }

  .toggle-manpower {
    float: right;
    width: 35px;
    height: 20px;
    border-radius: 10px;
    position: relative;
    &::after {
      content: ' ';
      position: absolute;
      width: 20px;
      height: 18px;
      background: get_color('neutral', 100);
      border-radius: 9px;
      right: 0;
      top: 0;
    }
    &.manpower-disabled {
      &::after {
        left: 0;
      }
    }
  }

  .thresholds-selector {
    .value-input {
      width: 60px;
      display: inline-block;
      position: relative;
    }
  }

  .thresholds-chart {
    &-container {
      position: relative;
      padding-top: 10px;

      .line {
        height: 1px;
        width: 100%;
        background-color: get_color('neutral', 70);

        &::before {
          content: '';
          border-right: 8px solid get_color('neutral', 70);
          border-top: 4px solid get_color('neutral', 100);
          border-bottom: 4px solid get_color('neutral', 100);
          height: 6px;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 6px;
        }

        &::after {
          content: '';
          border-left: 8px solid get_color('neutral', 70);
          border-top: 4px solid get_color('neutral', 100);
          border-bottom: 4px solid get_color('neutral', 100);
          height: 6px;
          display: inline-block;
          position: absolute;
          right: 0;
          top: 6px;
        }
      }

      .delimiter {
        width: 1px;
        height: 7px;
        top: 7px;
        position: absolute;
        background-color: get_color('neutral', 70);
      }

      .positive-arrow {
        height: 3px;
        right: 0;
        top: 9px;
        position: absolute;
        background-color: get_color('success', 70);

        &::before {
          content: '';
          background-color: get_color('success', 70);
          height: 7px;
          width: 7px;
          border-radius: 4px;
          display: inline-block;
          position: absolute;
          left: -3px;
          top: -2px;
        }

        &::after {
          content: '';
          border-left: 8px solid get_color('success', 70);
          border-top: 4px solid get_color('neutral', 100);
          border-bottom: 4px solid get_color('neutral', 100);
          height: 6px;
          display: inline-block;
          position: absolute;
          right: 0;
          top: -3px;
        }

        &.red {
          background-color: get_color('error', 50);
        }

        &.red::before {
          background-color: get_color('error', 50);
        }
        &.red::after {
          border-left: 8px solid get_color('error', 50);
        }
      }

      .mid-arrow {
        height: 3px;
        top: 9px;
        position: absolute;
        background-color: get_color('secondary', 60);
      }

      .negative-arrow {
        height: 3px;
        left: 0;
        top: 9px;
        position: absolute;
        background-color: get_color('error', 50);

        &::before {
          content: '';
          border-right: 8px solid get_color('error', 50);
          border-top: 4px solid get_color('neutral', 100);
          border-bottom: 4px solid get_color('neutral', 100);
          height: 6px;
          display: inline-block;
          position: absolute;
          left: 0;
          top: -3px;
        }

        &::after {
          content: '';
          background-color: get_color('error', 50);
          height: 7px;
          width: 7px;
          border-radius: 4px;
          display: inline-block;
          position: absolute;
          right: -4px;
          top: -2px;
        }

        &.green {
          background-color: get_color('success', 70);
        }

        &.green::before {
          border-right: 8px solid get_color('success', 70);
        }
        &.green::after {
          background-color: get_color('success', 70);
        }
      }
    }
  }
}
