@import '~/client/src/shared/theme.module';

.project-creation {
  .mapboxgl-marker {
    top: 10px;
    left: 10px;
  }
  .main-content {
    min-width: 80%;
    max-width: 80%;
    background-color: get_color('neutral', 96);
    padding-left: 40px;
  }

  .map-content {
    height: 100%;
  }

  .side-table {
    min-width: 300px;
    max-width: 300px;
  }

  .opacity-background {
    z-index: 10;
    opacity: 0.38;
    background-color: get_color('neutral', 0);
  }

  .loader-container {
    z-index: 100;
  }

  .map-box-editor-map {
    left: 0;
  }

  .text-for-mapbox {
    top: 20px;
    z-index: 10;
    left: 20px;
    -webkit-text-stroke: 0.2px get_color('neutral', 0);
  }
  .small-mapbox-preview {
    height: 80px;
    .overlays {
      display: none;
    }
    .mapboxgl-map,
    .mapbox-editor {
      border-radius: 10px;
    }
  }

  .select-sitemap-option-menu {
    padding: 20px;
    border-radius: 30px;
    width: 400px;
    position: absolute;
    z-index: 10;
    top: calc(50% - 200px);
    left: calc(50% - 125px);
    .upload-schedule-block {
      width: 160px;
      height: 80px;
    }

    .setup-form-map {
      height: 80px !important;
      width: 195px !important;
    }
    .setup-form-map-buttons,
    .mapboxgl-control-container,
    .mapboxgl-marker,
    .mapboxgl-marker-anchor-center {
      display: none;
    }
  }

  .setup-form-map {
    height: 100%;
    width: 100% !important;
    margin-left: 0 !important;
    display: flex;
    flex-direction: column;
  }

  .setup-form-map-container {
    height: 100%;
    width: 100% !important;
    margin-left: 0 !important;
    display: flex;
    flex-direction: column;
  }

  .submit-project-button {
    &.light {
      background-color: get_color('primary', 50);
      border: 1px solid get_color('primary', 50);

      &:hover {
        background-color: get_color('neutral', 100);
        color: get_color('primary', 50);
      }
    }
    border: 1px solid get_color('primary', 30);
    border-radius: 3px;
    color: get_color('neutral', 100);
    background-color: get_color('primary', 30);
    text-align: center;
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover {
      background-color: get_color('neutral', 100);
      color: get_color('primary', 30);
    }
  }

  .input-right-table {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    height: 40px;
    background: get_color('neutral', 96);
    border: 1px solid get_color('neutral', 92);
    border-radius: 4px;
  }

  .coordinates-wrapper {
    .bp3-popover-target {
      width: 100%;
    }
  }
}
