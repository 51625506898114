@import '~/client/src/shared/theme.module';

// Customize Blueprint Select control
.project-select {
  .bp3-active {
    button {
      border-bottom: none !important;
      padding-bottom: 50px;
      margin-top: 25px;
      line-height: 20px;
    }
  }

  .bp3-popover-open {
    .bp3-button {
      background-color: get_color('neutral', 90) !important;
      background-image: none;
    }
  }

  .bp3-button {
    display: inline-block;
    outline: none;
    width: 350px;
    height: 32px;
    border-radius: 4px;
    box-shadow: none;
    border: solid 1px get_color('neutral', 84);
    background-color: get_color('neutral', 100);

    & > span {
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 300px;
      margin-top: 1px;
    }

    .bp3-icon {
      float: right;
      margin-top: 5px;
      svg {
        fill: get_color('neutral', 30);
        height: 10px;
      }
    }

    &:hover {
      background-color: initial;
    }
  }
}

.bp3-select-popover.project-select-popover {
  .bp3-popover-arrow {
    display: none;
  }

  .bp3-menu {
    width: 360px;
    max-height: 300px;
    overflow: auto;

    &-item {
      background-color: inherit;
      border-radius: 4px;

      &:hover {
        background-color: get_color('blue', 88);
      }

      &.active-project {
        .project-name,
        .project-address,
        .project-time {
          color: get_color('secondary', 40);
        }

        .active-check-icon {
          svg {
            width: 24px;
            height: 24px;

            path {
              fill: get_color('secondary', 40);
            }
          }
        }
      }
    }
  }
}

.project-name {
  padding-bottom: 2px;
  font-weight: 600;
  color: get_color('neutral', 0);
}

.project-address {
  white-space: normal;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: get_color('neutral', 30);
}

.project-time {
  font-size: 11px;
  color: get_color('neutral', 60);
}

.project-date {
  font-size: 12px;
  color: get_color('neutral', 50);
}

.base-line {
  height: 10px;
  width: 125px;
  border: 2px solid get_color('primary', 70);

  .progress-line {
    height: 8px;
    background-color: get_color('primary', 70);
  }
}
