@import '~/client/src/shared/theme.module';

$default-block-height: 65px;

.workflow-diagram-wrapper.permit-type-diagram > .workflow-diagram {
  %vertical-line {
    z-index: 1;
    position: absolute;
  }

  .empty-block {
    height: $default-block-height;
  }

  .changed-block {
    top: -89px;
  }

  .bottom-step-block {
    top: 28px;
  }

  .change-action-state,
  .fail-action-state,
  .daily-inspection-label {
    top: -46px;
  }

  .change-line {
    top: -75px;
  }

  .vertical-left-line {
    @extend %vertical-line;

    width: 64px;
    height: 50px;
    left: -28px;
    border-right: 2px solid;
    border-top-right-radius: 6px;
  }

  .vertical-left-line-long {
    @extend %vertical-line;

    width: 64px;
    height: 60px;
    left: -24px;
    border-right: 2px solid;
    border-top-right-radius: 6px;
  }

  .vertical-right-line {
    @extend %vertical-line;

    width: 71px;
    height: 52px;
    border-right: 1px solid;
    border-left: 1px solid;
    border-top-left-radius: 6px;
  }

  .arrow-left {
    right: -11px;
  }

  .arrow-down {
    top: 11px;
  }

  .daily-inspection-block {
    height: 63px;
    border: 2px solid;
  }

  .change-action-line-container {
    top: -13px !important;
  }

  .change-step-block {
    top: -63px !important;
  }
}
