@import '~/client/src/shared/theme.module';

.object-row {
  .interval-wrapper {
    min-height: 50px;
    max-height: 50px;

    &.active {
      background-color: get_color('error', 88);
    }

    &:hover {
      background-color: get_color('primary', 96);
    }

    .permit-type-icon svg {
      width: 18px;
      height: 18px;
    }
  }

  .name-cell {
    &.opened {
      position: absolute;
      z-index: 10;
      background-color: get_color('neutral', 100);
      left: 15px;
      top: 0;
      width: 90%;
      box-shadow: -4px 5px 6px -2px get_color('neutral', 0);
      .action-button {
        svg {
          width: 12px;
          height: 12px;
        }
        svg g g {
          fill: get_color('neutral', 50);
        }
      }

      .name-cell-content {
        max-height: 400px;

        .building-levels-selector {
          max-height: 85% !important;
        }
      }
    }
    &:not(.opened):hover {
      background-color: get_scss_colors('primary', 92);
      box-shadow: 0px 5px 6px -2px get_color('neutral', 0);
      .additional-properties-icon {
        visibility: visible;
        opacity: 1;
      }
    }
    .additional-properties-icon {
      visibility: hidden;
      opacity: 0;
    }
  }

  .levels-closure-modal {
    top: 10px;
    left: 8px;
    background-color: get_color('neutral', 100);
    z-index: 2;
    width: 340px;
    color: get_color('neutral', 50);

    .grey-input {
      width: 100%;
      max-width: 290px;
      height: 48px;
      background-color: get_color('neutral', 96);
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid get_color('neutral', 70);
      cursor: pointer;
    }

    .building-levels-selector {
      position: absolute;
      width: 260px;
      height: fit-content;
      background-color: get_color('neutral', 100);
      border-radius: 4px;
      left: 40px;
      top: 32px;
      box-shadow: 0 10px 30px 0 get_color('neutral', 50);
      z-index: 10;
      max-height: 100% !important;
    }

    &-submit {
      background-color: get_color('primary', 50);
      color: get_color('neutral', 100);
      flex-basis: 100px;
    }
  }

  .hierarchy-chains-container {
    max-width: 205px;
  }

  .add-number-menu {
    z-index: 10;
    top: -40px;
    border: 1px solid get_color('neutral', 84);
    box-shadow: 0px 20px 20px -20px get_color('neutral', 0, 0.4);
  }
  .input-number {
    color: get_color('primary', 30);
    width: 100%;
    border: none;
    border-bottom: 1px solid get_color('neutral', 84);
  }

  .add-button-text {
    &:hover {
      color: get_color('neutral', 100) !important;
      background-color: get_color('primary', 50) !important;
    }
  }

  .sitemap-items-color-picker .sitemap-items-colors-container {
    max-height: 270px;
  }
}
