@import '~/client/src/shared/theme.module';

$MOBILE_PREVIEW_WIDTH: 380px;
$CONFIGURATOR_DESCRIPTION_WIDTH: 175px;

.tracking-form {
  .bp3-control {
    input:disabled ~ .bp3-control-indicator {
      border-color: get_color('neutral', 70);
    }

    &:not(.bp3-align-right) {
      padding-left: 35px;
      padding-top: 0;
      margin: 0;
    }

    &.bp3-switch {
      margin: 0;
      display: inline-block;
    }

    &.bp3-align-right {
      padding: 0;

      .bp3-control-indicator {
        margin: 0;

        &::before {
          left: -5px;
        }
      }

      input:checked ~ .bp3-control-indicator {
        &::before {
          left: 0.6em;
        }
      }
    }
  }

  .mobile-preview {
    min-width: $MOBILE_PREVIEW_WIDTH;
    max-width: $MOBILE_PREVIEW_WIDTH;
  }

  .configurator-description {
    align-self: flex-start;
    min-width: $CONFIGURATOR_DESCRIPTION_WIDTH;
    max-width: $CONFIGURATOR_DESCRIPTION_WIDTH;
  }

  .activity-tree-node {
    pointer-events: none;
  }
}
