@import '~/client/src/shared/theme.module';

button.base-action-btn.primary-theme.no-hover {
  &:hover {
    color: get_color('primary', 30);
    border-color: get_color('primary', 30);
    background-color: get_color('neutral', 100);
    cursor: default;
  }
}

button.base-action-btn.primary-theme-inverted.no-hover {
  &:hover {
    color: get_color('neutral', 100);
    border-color: get_color('neutral', 100);
    background-color: get_color('primary', 30);
    cursor: default;
  }
}
