@import '~/client/src/shared/theme.module';

$scroll-resizer-width: 6px;
$app-icon-size: 22px;
$admins-list-width: 500px;

.title-orange-icon {
  svg path {
    fill: get_color('secondary', 40);
  }
}
.closing-intervals-holder {
  max-height: 170px;
  width: 500px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: $scroll-resizer-width;
    background: get_color('neutral', 92);
  }

  &::-webkit-scrollbar-thumb {
    background: get_color('neutral', 50);
  }
}

.setup-form-map {
  display: flex;
  flex-direction: row;
}

.setup-form-map-container {
  height: 300px;
  width: 600px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.book-demo-holder {
  max-width: 120px !important;
  height: 32px !important;
}
.apps-icon {
  width: $app-icon-size;
  height: $app-icon-size;
  svg {
    width: $app-icon-size;
    height: $app-icon-size;
  }
}
.apps-toggles-holder {
  max-width: 200px;
  .bp3-control {
    padding-left: 0 !important;
    .bp3-control-indicator {
      margin-left: 30px !important;
    }
  }
}

.admins-list-selector {
  left: 30px;
  top: 0;
  z-index: 100;
  min-width: 375px;
  height: 400px;
  max-width: 100%;
}

.setup-form-map-buttons {
  position: absolute;
  margin-left: 60px;

  button {
    display: inline-block;
    cursor: pointer;
    background: get_color('neutral', 100);
    border: 0;
    outline: none;

    &.active {
      font-weight: 600;
    }
  }
}
.admins-list {
  max-width: $admins-list-width;
  min-width: $admins-list-width;
  .admin-row {
    max-width: $admins-list-width;
  }
}
.setup-project-address,
.setup-form-working-hours {
  width: 350px;
  border-radius: 4px;
  background-color: get_color('neutral', 96);
  border: none;
  flex-shrink: 0;
  padding: 10px;
}
.closing-interval-holder {
  height: 50px;
  &:hover {
    .closing-interval-value {
      background-color: get_color('neutral', 84);
    }
    .delete-interval-icon {
      visibility: visible;
      opacity: 1;
    }
  }
  .delete-interval-icon {
    visibility: hidden;
    opacity: 0;
  }
}
.qr-code-holder {
  min-width: 350px;
  .qr-code-download {
    right: 0;
  }
}
.qr-code-to-print-holder {
  height: auto;
  width: 100%;
}
.toggle-text-with-tooltip {
  min-width: 350px;
}
.date-time-format,
.timezone {
  width: 350px;
}

.confirmation-modal {
  width: 500px;
}

.project-details {
  .date-closures-picker {
    top: 5% !important;
  }
}
