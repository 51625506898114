@import '~/client/src/shared/theme.module';

$notification-filter-icon-size: 16px;
$list-item-offset: 5px;

@mixin box-outline {
  outline: 1px solid get_color('neutral', 84);
  border-left: 1px solid get_color('neutral', 84);
}

@mixin dark-box-outline {
  outline: 1px solid get_color('neutral', 80);
}

@mixin list-item {
  min-height: 70px;
  display: block;
  padding-top: $list-item-offset;
  padding-bottom: $list-item-offset;
  background-color: get_color('neutral', 100);
  cursor: pointer;
}

@mixin photo-holder {
  cursor: pointer;
  height: 70px;
  margin-right: 5px;
  transition: 0.5s all;
}

@mixin filter {
  cursor: pointer;
  padding: 9px 6px 3px 12px;
}

.notifications-group-label {
  position: absolute;
  top: 13px;
  left: 10px;
}

.select-all-button {
  color: get_color('neutral', 50);
  border: 1px solid get_color('neutral', 50);
  font-size: 12px;
  padding: 0 5px;
  cursor: pointer;
  border-radius: 10px;
}

.notification-filter-icon {
  max-width: $notification-filter-icon-size;
  height: $notification-filter-icon-size;
  &.light-blue {
    background-color: get_color('primary', 70);
  }
  &.blue {
    background-color: get_color('primary', 30);
  }
  &.red {
    background-color: get_color('error', 50);
  }
  &.yellow {
    background-color: get_color('warning', 40);
  }
  &.orange {
    background-color: get_color('secondary', 40);
  }
  &.green {
    background-color: get_color('success', 40);
  }
  &.gray {
    background-color: get_color('neutral', 50);
  }
}

.notifications {
  &-left-nav {
    overflow: auto;
    min-width: 200px;
    max-width: 200px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: get_color('primary', 30);
    }
  }

  &-content-area {
    max-width: calc(100% - 200px);
  }

  &-list-item-unread {
    @include box-outline;
    @include list-item;
    font-weight: 600;
  }

  &-list-item-read {
    @include box-outline;
    @include list-item;
    font-weight: normal;
    background-color: get_color('grey', 96);
  }

  &-list-item-active {
    @include box-outline;
    @include list-item;
    background-color: get_color('primary', 88);
  }

  &-list-item-read:hover,
  &-list-item-unread:hover {
    @include dark-box-outline;
    z-index: 1;
  }

  &-header {
    padding-left: 10px;
    padding-right: 10px;
    min-height: 30px;
    .options-list {
      box-shadow: 0px 0px 5px 0px get_color('neutral', 70, 0.3);
      position: absolute;
      width: 111px;
      z-index: 1;
      top: 20px;
      background-color: get_color('neutral', 100);
      left: 35px;
      border: 1px solid get_color('neutral', 70);
      .option {
        padding: 5px 10px;
        &.active {
          background-color: get_color('primary', 88);
        }
        &:hover {
          background-color: get_color('grey', 84);
        }
      }
    }
    .dropdown {
      min-width: 45px;
      padding: 5px;
      &.active {
        background-color: get_color('neutral', 30, 0.17) !important;
      }
      &:hover {
        background-color: get_color('neutral', 80, 0.17);
      }
      .filter-option-checkbox-container {
        flex: 0 0 16px !important;
      }
    }
  }

  &-sideBar {
    padding: 0 15px 15px 15px;
    min-width: 200px;

    .companies-bar {
      max-height: calc(100% - 555px);

      &.expanded {
        height: unset;
      }

      &-container {
        height: calc(100% - 20px);

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: get_color('primary', 30);
        }

        .filter-label {
          max-width: 100px;
          display: inline-block;
        }
      }
    }
  }

  &-filter {
    &.active {
      @include filter;
      background-color: get_color('primary', 88, 0.5);
    }
  }

  &-filter {
    @include filter;
  }

  &-sidebar-section {
    color: get_color('neutral', 50);
  }

  &-sidebar-section-title {
    &.active {
      background-color: get_color('primary', 88, 0.5);
    }
    padding: 12px 6px 3px 6px;
    margin-bottom: 3px;
    border-bottom: 1px solid get_color('neutral', 84);
  }
}

.notification-details-holder {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: get_color('neutral', 100);
  outline: 1px solid get_color('neutral', 84);
}

.delivery-states-line {
  overflow-x: auto;
  max-width: 100%;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: get_color('primary', 30);
  }
}

.photo-container:hover ~ .photo-container-first {
  max-width: 5px !important;
}
.photo-container-first {
  @include photo-holder;
  max-width: 120px;
}
.photo-container {
  @include photo-holder;
  max-width: 5px;
  &:hover {
    max-width: 120px;
  }
}
