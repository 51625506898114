@import '~/client/src/shared/theme.module';

$navigation-header-height: 131px;

.page-name-tab {
  margin: 0 35px 15px;
}

.area-select-popover {
  width: 200px;

  .bp3-active {
    background-color: inherit !important;
    color: inherit !important;
  }

  .bp3-menu {
    overflow: auto;
    max-height: 500px;
    border-radius: 4px;
    box-shadow: 0 20px 50px -20px get_color('neutral', 50);
    z-index: 10;
    border: 1px solid get_color('grey', 84);
  }
}

.delivery-details-preview {
  flex-basis: 450px;
  height: 100%;
  background-color: get_color('neutral', 30);

  .fields-container {
    background-color: get_color('neutral', 100);
    border-radius: 4px 4px 0 0;
  }
}
