@import '~/client/src/shared/theme.module';
@import '~/client/src/shared/sizes.scss';

$dot-size: 5px;

.band-left-column-dot {
  border: 3px solid get_color('neutral', 0);
  background-color: get_color('neutral', 0);
  width: $dot-size;
  height: $dot-size;
}

.custom-grouping-band-container {
  max-height: 300px;
  width: 300px;
  overflow-y: auto !important;

  .indent-option + .indent-option {
    display: none !important;
  }

  .bp3-menu-item {
    cursor: default;
    padding: 0 !important;
    outline: none !important;
    background: none !important;

    &:hover,
    &.bp3-active {
      background: none !important;
    }
  }
}

.grouping-options-container {
  z-index: 100;
  border: 1px solid get_color('neutral', 70);
  border-radius: 2px;
  height: unset !important;
  max-height: calc(100vh - 210px);

  .reset-button {
    max-width: 73px;
    min-width: 73px;
    height: 20px;
    align-items: initial;

    border: solid 1px get_color('primary', 30);
    background-color: get_color('neutral', 100);
    color: get_color('primary', 30);
  }

  .filter-footer {
    position: relative !important;
    min-height: 56px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;

    .filter-footer-btn-cancel {
      position: relative !important;
      bottom: 0 !important;
      left: 0 !important;
    }

    .filter-footer-btn-apply {
      position: relative !important;
      bottom: 0 !important;
      right: 0 !important;
    }
  }
}

.grouping-options-btn-cancel {
  position: absolute;
  bottom: 20px;
  left: 30px;
  color: get_color('primary', 30);
  font-size: 18px;

  &:hover {
    cursor: pointer;
  }
}

.grouping-options-btn-apply {
  position: absolute;
  bottom: 20px;
  right: 30px;
  color: get_color('primary', 30);
  font-size: 18px;

  &:hover {
    cursor: pointer;
  }
}
