@import '~/client/src/shared/theme.module';

$photos-header-bar-height: 50px;

$scroll-resizer-width: 4px;

$deliveries-photos-side-bar-width: 220px;
$view-row-width: 160px;
$mode-row-width: 250px;
$photo-row-width: 220px;

.deliveries-photos-side-bar {
  min-width: $deliveries-photos-side-bar-width;

  &::-webkit-scrollbar {
    width: $scroll-resizer-width;
  }

  &::-webkit-scrollbar-track {
    background: get_color('neutral', 84);
  }

  &::-webkit-scrollbar-thumb {
    background: get_color('neutral', 60);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: get_color('neutral', 70);
  }

  .multi-select-container {
    border-top: 1px solid get_color('neutral', 84);

    .multiselect-checkbox-item {
      padding: 5px 0;

      .bp3-control-indicator {
        margin-right: 5px !important;
      }
    }
  }
}

.deliveries-photos-content {
  height: calc(100% - 42px);
  &::-webkit-scrollbar {
    width: $scroll-resizer-width;
  }

  &::-webkit-scrollbar-track {
    background: get_color('neutral', 84);
  }
  &::-webkit-scrollbar-thumb {
    background: get_color('neutral', 60);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: get_color('neutral', 70);
  }
}

.photo-row {
  min-height: $photo-row-width;

  .item {
    flex: auto;
    max-width: 200px;
  }
}

.photo-description {
  max-height: 30px;
}

.photos-holder {
  height: 100%;
  display: flex;
  flex-direction: column;

  .photos-header-bar {
    height: $photos-header-bar-height;

    .filters-row {
      height: $photos-header-bar-height;

      .row {
        height: 32px;
      }

      .mode-row {
        min-width: $mode-row-width;
        max-width: $mode-row-width;
        border-right: 2px solid get_color('neutral', 84);
      }

      .view-row {
        min-width: $view-row-width;
        max-width: $view-row-width;
        border-right: 2px solid get_color('neutral', 84);
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: get_color('primary', 30);
    }

    &::-webkit-scrollbar {
      height: 8px;
    }
  }

  .photos-content {
    height: calc(100% - #{$photos-header-bar-height});
    overflow-y: hidden;
  }

  .photo-holder {
    max-width: 200px;
    min-width: 100px;
    height: 170px;
    flex-shrink: 0;
    flex-grow: 0;
    object-fit: contain;
    overflow: hidden;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.pagination {
  li {
    display: flex;
    justify-content: center;
  }
  a {
    max-width: 20px;
    min-width: 20px;
    text-align: center;
    outline: none;
  }
  .active {
    a {
      border: 1px solid;
      border-radius: 10px;
    }
  }
}
