@import '~/client/src/shared/theme.module';

$row-height: 48px;

.project-setup-mainframe .tags-directory .tags-directory-list {
  .tag-cell-editor {
    top: -24px;
    left: -20px;
    z-index: 3;

    .tag-input-row {
      height: $row-height;
    }

    .tag-color-text {
      height: 32px;
    }

    .colors-column {
      max-height: 250px;
    }

    .tag-color-row {
      height: $row-height;

      .tag-color-selected,
      .tag-color-selected svg {
        height: 16px;
        width: 16px;
      }
    }

    .hierarchy-delivery-attribute-tag {
      width: 36px;
      height: 24px;
      justify-content: center;
      align-items: center;
    }

    .hierarchy-delivery-attribute-tag-icon {
      padding: 0 !important;
    }
  }
}
