@import '~/client/src/shared/theme.module';

.activity-name-filter {
  &-handle-button {
    max-width: 50px;
    min-width: 50px;
  }

  .search-input {
    height: 60px;
    min-width: 200px;
    position: absolute;
    z-index: 12;

    &.long {
      min-width: 350px;
    }

    &.top-oriented {
      bottom: 50px;
    }

    &.left-oriented {
      right: 0;
    }

    &.hide {
      display: none;
    }
  }
}
