@import '~/client/src/shared/theme.module';

.gant-side-bar {
  position: absolute;
  width: 400px;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  backface-visibility: hidden;
  z-index: 15;
  background: get_color('neutral', 100);
  transition: transform 333ms cubic-bezier(0.55, 0, 0.1, 1);
  padding-left: 0;

  &.small {
    height: calc(100% - 78px);
  }

  &.closed {
    transform: translate(424px, 0px);
    box-shadow: none;
  }

  &.opened {
    transform: translate(0px, 0px);
    box-shadow: -10px 5px 20px get_color('neutral', 0, 0.4);
  }

  &.inline {
    position: static;
    width: 100%;
  }
}
