@import '~/client/src/shared/theme.module';

.tree {
  .bp3-icon {
    color: get_color('primary', 30);
  }

  .child-block {
    border-left: 1px solid get_color('primary', 30);
    margin-left: -13px;
    padding-left: 13px;
  }

  .only-holder {
    .only-button {
      display: inline-block;
      vertical-align: top;
      visibility: hidden;
      opacity: 0;
      margin: 0 4px;
      font-size: 12px;
      color: get_color('neutral', 50);
      border: 1px solid get_color('neutral', 84);
      border-radius: 10px;
      padding: 0 3px;
      cursor: pointer;
      transition: opacity 0.25s;

      &:hover {
        background-color: get_color('primary', 88, 0.5);
      }

      &:active {
        color: get_color('neutral', 100);
        border-color: get_color('primary', 30);
        background-color: get_color('primary', 30);
      }
    }

    &:hover {
      .only-button {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
