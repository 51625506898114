@import '~/client/src/shared/theme.module';

.map-box-suggest-input {
  &:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    outline: none !important;
  }

  > input {
    height: 40px;
    outline: none !important;
    border-radius: 4px;
    padding: 7px 8px;
    font-size: 16px;
    line-height: 24px;
    background: get_color('neutral', 96);
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border: 1px solid get_color('primary', 50) !important;

    &:focus {
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
  }
}
