@import '~/client/src/shared/theme.module';

.global-map-setup {
  .sitemap-ribbon {
    top: 80px !important;
    right: unset !important;
  }
}

$sitemaps-left-bar-width: 300px;
$add-icon-size: 20px;
$sitemap-ribbon-height: 65px;

$location-association-modal-width: 375px;

.general-map-setup {
  .current-globe-name {
    z-index: 100;
    left: 10px;

    border: 1px solid get_color('neutral', 92);
    box-shadow: 0px 2px 2px get_color('neutral', 0, 0.25),
      0px 3px 1px get_color('neutral', 0, 0.25),
      0px 1px 5px get_color('neutral', 0, 0.25);
    border-radius: 8px;
    z-index: 12;
    left: 12px;
    height: 36px;
    box-shadow: 0 10px 30px 0 get_color('neutral', 50);
    max-width: 175px;
  }

  .location-icon-grey {
    svg path {
      fill: get_color('neutral', 70);
    }
  }

  .top-view-row {
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 6;
    .view-select-modal {
      &:hover {
        background-color: get_color('neutral', 92);
      }
      width: 200px;
      height: 32px;
      border: 1px solid get_color('neutral', 92);
      box-shadow: 0px 1px 3px 0px get_color('neutral', 0, 0.3);
    }
  }

  .header-cell,
  .header-small-cell {
    min-height: 42px;
  }

  .bp3-icon.bp3-icon-chevron-right {
    position: static;
  }

  .georeferencing-tooltip {
    background-color: get_color('neutral', 100);
    padding: 20px;
    border-radius: 20px;
    width: 540px;
    position: absolute;
    z-index: 10;
    top: calc(50% - 200px);
    left: calc(50% - 270px);
  }

  .location-objects-tooltip {
    height: calc(100% - 100px);
    background-color: get_color('neutral', 100);
    padding: 20px;
    border-radius: 20px;
    width: 540px;
    position: absolute;
    z-index: 10;
    max-height: 720px;
    left: calc(50% - 270px);

    .tooltip-content {
      overflow: auto;
      height: calc(100% - 100px);
    }
  }

  .map-controls-instruction {
    height: 40px;
  }

  .location-name {
    max-width: 100px;
    min-width: 100px;
  }

  .georeference-instructions {
    background-color: get_color('neutral', 100);
    padding: 20px;
    border-radius: 20px;
    width: 540px;
    position: absolute;
    z-index: 10;
    top: calc(50% - 290px);
    left: calc(50% - 270px);
  }

  .submit-project-button {
    &.light {
      background-color: get_color('primary', 50);
      border: 1px solid get_color('primary', 50);

      &:hover {
        background-color: get_color('neutral', 100);
        color: get_color('primary', 50);
      }
    }
    border: 1px solid get_color('primary', 30);
    border-radius: 3px;
    color: get_color('neutral', 100);
    background-color: get_color('primary', 30);
    text-align: center;
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover {
      background-color: get_color('neutral', 100);
      color: get_color('primary', 30);
    }
  }

  .updating-sitemap-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: get_color('neutral', 100, 0.3);
    z-index: 10000;

    .logo-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      pointer-events: none;
    }
  }

  .location-association-modal {
    position: absolute;
    max-height: 450px;
    background-color: get_color('neutral', 100);
    left: -250px;
    top: 12px;
    z-index: 101;
    width: $location-association-modal-width;
    border-radius: 4px;
    box-shadow: 0 20px 50px -20px get_color('neutral', 50);
  }

  .main-area {
    user-select: none;

    .filter-container-background {
      z-index: 102 !important;
    }

    .map-upload-block {
      z-index: 103 !important;
    }

    .sitemaps-left-bar {
      flex-basis: $sitemaps-left-bar-width;
      max-width: $sitemaps-left-bar-width;

      .caret-down {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;

        border-top: 6px solid get_color('neutral', 0);
      }

      .caret-right {
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;

        border-left: 6px solid get_color('neutral', 0);
      }

      .dot {
        display: inline-block;
        border: 1px solid get_color('neutral', 84);
        border-radius: 3px;
        width: 6px;
        height: 6px;
      }

      .selected-node {
        background-color: get_color('primary', 96);
      }

      .node-container {
        width: calc(100% - 30px);

        .hierarchy-delivery-attribute-tag {
          max-width: 100%;
        }
      }
    }

    &-container {
      flex-basis: calc(100% - #{$sitemaps-left-bar-width});

      .sitemap-ribbon {
        .view-select {
          border: 1px solid get_color('grey', 84);
          border-radius: 4px;
          width: 350px;
          height: 32px;

          &.opened {
            background-color: get_color('primary', 96);
          }

          .select-arrow-down {
            svg {
              width: 12px;
              height: 12px;
              path {
                fill: get_color('neutral', 60);
              }
            }
          }

          &-dialog {
            top: $sitemap-ribbon-height;
            z-index: 20;
            max-height: 790px;
            overflow-y: auto;
            width: 1050px;
            border: 1px solid get_color('neutral', 60);
            border-radius: 4px;
            box-shadow: 0 10px 30px 0 get_color('neutral', 70);
            background-color: get_color('neutral', 100);
            &::-webkit-scrollbar {
              width: 0px;
              background: transparent;
            }

            .cross-icon {
              height: 32px;

              svg path:last-child {
                fill: get_color('neutral', 60);
              }
            }
          }
        }

        .add-icon {
          svg {
            height: $add-icon-size;
            width: $add-icon-size;
          }
        }

        .sitemap-loader svg {
          width: 23px;
          height: 23px;
          -webkit-animation: loader 1s linear infinite;
          -moz-animation: loader 1s linear infinite;
          animation: loader 1s linear infinite;

          path {
            fill: get_color('primary', 30);
          }

          @-moz-keyframes loader {
            100% {
              -moz-transform: rotate(360deg);
            }
          }
          @-webkit-keyframes loader {
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          @keyframes loader {
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        }
      }
    }

    .sitemap-editor {
      &-container {
        height: calc(100% - #{$sitemap-ribbon-height});
      }
    }

    .general-map-setup-dnd-area {
      &-title {
        padding: 100px 50px;
        min-height: 300px;
      }
    }
  }
}

.set-up-steps-row {
  background-color: get_color('magenta', 96);
  padding: 10px;
  font-weight: bold;
  .item {
    text-align: center;
    padding: 5px;
    border-radius: 10px;
    margin: 5px;
    &.active {
      background-color: get_color('primary', 30, 0.12);
      color: get_color('neutral', 100);
    }
  }
}

.transparency-text {
  line-height: 16px;
  color: get_color('neutral', 0, 0.62);
  font-weight: 600;
}
.horizontal-slider {
  width: 100%;
  height: 25px;

  .example-thumb {
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: get_color('neutral', 100);
    color: get_color('neutral', 100);
    border-radius: 50%;
    cursor: grab;
    border: 1px solid get_color('neutral', 50);
  }
  .example-track-0 {
    background: get_color('neutral', 100);
    border-radius: 10px;
    border: 1px solid get_color('neutral', 50);
    height: 25px;
  }
  .example-track-1 {
    background: get_color('neutral', 84);
    border-radius: 10px;
    border: 1px solid get_color('neutral', 50);
    height: 25px;
  }
}

.opacity-slider {
  box-shadow: 0 4px 5px 0 get_color('neutral', 84);
  left: 20px;
  bottom: 115px;
  z-index: 1000;
  .slider-holder {
    width: 100px;
  }
  input {
    background-color: get_color('neutral', 96);
    border: none;
    -webkit-appearance: none;
    padding: 5px;
    margin: 0 5px;
    max-width: 40px;
    border-radius: 10px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.publish-to-icon svg circle {
  fill: get_color('violet', 40);
}

.sitemap-edit-header-row {
  height: 50px;
  .main-header-cell {
    min-width: 30%;
    .current-map {
      max-width: calc(100% - 16px);
      .editable-label-holder {
        max-width: calc(100% - 40px) !important;
      }
    }
  }
  .header-cell {
    max-width: 25%;
  }
  .editable-label {
    background-color: unset;
    height: 24px;
  }
}

.sitemap-edit-content {
  height: calc(100% - 50px);
}

.general-map-container {
  background-color: get_color('neutral', 0, 0.27);

  .zoom-buttons {
    position: absolute;
    top: 100px;
    right: 20px;

    .zoom-buttons-item {
      background-color: get_color('neutral', 100);
      border: 1px solid get_color('primary', 30);
      margin-bottom: 5px;

      svg {
        fill: get_color('primary', 30);
      }
    }
  }
  .show-on-menu {
    left: 10px;
    bottom: 10px;
    z-index: 5;

    .option-icon svg path {
      fill: get_color('neutral', 0);
    }

    .show-on-text-box {
      height: 30px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      background-color: get_color('neutral', 100);
    }

    .show-on-icons-box {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 30px;
      background-color: get_color('neutral', 92);

      &.selected {
        color: get_color('primary', 50) !important;
        .eye-icon svg g path {
          fill: get_color('primary', 50) !important;
        }
        .option-icon svg path {
          fill: get_color('primary', 50) !important;
        }
        .caret-icon svg path {
          fill: get_color('primary', 50) !important;
        }
      }

      .eye-icon svg g path {
        fill: get_color('neutral', 50) !important;
      }

      .icon-holder {
        min-width: 20px;
        border-radius: 25px;
        height: 20px;
        background-color: get_color('primary', 50) !important;
        &.selected {
          background-color: get_color('primary', 96) !important;
        }
        &:hover {
          background-color: get_color('primary', 96);
        }
      }
    }

    .show-on-options {
      background-color: get_color('neutral', 100);
      bottom: 35px;
      left: 0;
      width: 250px;

      .option-holder {
        &.first-option {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        &.last-option {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        height: 35px;
        &:hover {
          background-color: get_color('primary', 96);
        }
        &.selected {
          background-color: get_color('primary', 96) !important;
        }
      }
    }
  }

  .map-buttons {
    position: absolute;
    top: 220px;
    right: 20px;

    .map-buttons-item {
      background-color: get_color('neutral', 100);
      border: 1px solid get_color('primary', 30);
      margin-bottom: 5px;
      z-index: 1;

      svg {
        fill: get_color('primary', 30);
      }
    }
  }

  .map-calc-buttons {
    position: absolute;
    top: 260px;
    right: 20px;

    .map-calc-buttons-item {
      background-color: get_color('neutral', 100);
      border: 1px solid get_color('primary', 30);
      margin-bottom: 5px;
      z-index: 1;

      svg {
        fill: get_color('primary', 30);
      }
    }
  }
}

.parent-select-popover {
  max-height: 500px;
  overflow: auto;
}

.parent-select-popover,
.property-select-popover {
  width: 250px;

  .bp3-active {
    background-color: inherit !important;
    color: inherit !important;
  }

  .bp3-menu {
    overflow: auto;
  }
}

.property-select-popover.inline-selector {
  width: 70px;

  .bp3-menu {
    min-width: 70px;
  }
}

.pdf-selector-dialog.bp3-dialog {
  width: 60%;
  max-height: 85vh;
  padding: 0;

  .bp3-dialog-header {
    .bp3-heading {
      font-size: 16px;
    }
  }

  .bp3-dialog-body {
    margin: 5px 10px;

    .pdf-document {
      display: flex !important;
      flex-direction: row;
      justify-content: center;

      .pdf-page-view canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .bp3-dialog-footer {
    .submit-btn:hover {
      background-color: get_color('violet', 40, 0.96);
    }
  }
}

.globe-view {
  .left-panel {
    max-width: 270px;
    &-header {
    }
  }

  .object-pallet {
    width: 100%;
    left: 10px;
    top: 55px;
    z-index: 5;

    .object {
      display: inline-block;
      width: 32px;
      height: 32px;
      background-color: get_color('neutral', 100);
      border: 1px solid get_color('neutral', 60);
      vertical-align: top;
      box-shadow: 0px 3px 20px -5px get_color('neutral', 0, 0.4);
      cursor: pointer;

      &.right-rounded {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &.left-rounded {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &.fully-rounded {
        border-radius: 6px;
      }

      &-icon {
        padding: 3px;

        .arrow {
          position: absolute;
          width: 0;
          height: 0;
          right: 2px;
          bottom: 2px;
          border-top: 5px solid transparent;
          border-right: 5px solid get_color('neutral', 60);

          &.expanded {
            border-right: 5px solid get_color('neutral', 0);
          }
        }
      }

      &:hover > .object-children {
        display: block;
      }

      &-children {
        background-color: get_color('neutral', 100);
        box-shadow: 0px 3px 20px -5px get_color('neutral', 0, 0.4);
        border: 1px solid get_color('neutral', 60);
        left: -2px;
        top: 30px;
        display: none;

        &:hover {
          display: block;
        }

        div {
          padding: 4px;
          border-bottom: 1px solid get_color('neutral', 60);

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
