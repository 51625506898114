@import '~/client/src/shared/theme.module';

.number-input-tooltip .bp3-popover-content {
  padding: 4px 8px 4px 8px !important;
  .tooltip {
    .white {
      color: get_color('neutral', 100);
    }

    .button-label {
      border: 1px solid gray;
    }
  }
}

.number-input-group {
  margin-top: 3px;
  .input-icon {
    width: 28px;
    height: 28px;
    gap: 8px;
    border: 1px solid;
    i svg {
      color: get_color('neutral', 10);
    }
    &.remove {
      border-radius: 4px 0px 0px 4px;
    }
    &.add {
      border-radius: 0px 4px 4px 0px;
      margin-left: -1px;
    }
  }
}
