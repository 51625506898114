@import '~/client/src/shared/theme.module';

$assign-sitemap-modal-width: 200px;
$action-row-title-width: 150px;
$action-row-height: 30px;

.assign-sitemap-dialog {
  position: absolute;
  background-color: get_color('neutral', 100);
  left: 0;
  top: 30px;
  z-index: 9;
  width: $assign-sitemap-modal-width;
  box-shadow: 0 0 10px 0 get_color('neutral', 0, 0.4);

  &.scrollable-content {
    display: inline-block;
    max-height: 280px;
  }

  &.options {
    top: -5px;
    left: 199px;
  }

  .action-row {
    height: $action-row-height;

    &-title {
      flex-basis: $action-row-title-width;
    }
  }

  .group {
    height: 32px;

    .gate {
      g {
        stroke: get_color('neutral', 0);
      }
    }

    &:not(.active) {
      .caret-icon {
        path {
          fill: get_color('neutral', 60);
        }
      }
    }

    &.active {
      background-color: get_color('neutral', 60);
    }
  }

  .option {
    height: 32px;
    width: fit-content;
    max-width: 100%;
    padding-left: 5px;
  }

  .separator {
    border-bottom: 1px solid get_color('neutral', 84);
    margin: 5px 15px;
  }
}
