@import '~/client/src/shared/theme.module';

$report-icon-size: 20px;
$report-z-index: 100;

.delivery-report-container {
  top: 0;
  left: 0;
  z-index: $report-z-index;
  background-color: get_color('neutral', 0, 0.38);

  .delivery-report-modal {
    width: 800px;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background-color: get_color('neutral', 100);
    z-index: $report-z-index;
    border-radius: 8px;
    box-shadow: 0 11px 15px 0 get_color('primary', 0, 0.2),
      0 9px 46px 0 get_color('primary', 0, 0.12),
      0 24px 38px 0 get_color('primary', 0, 0.14);

    .report-icon {
      width: $report-icon-size;
      height: $report-icon-size;
    }

    .filter-modal-main-content {
      height: calc(100% - 188px);
    }
    .filters-row {
      width: 30%;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
