@import '~/client/src/shared/theme.module';

$bic-min-width-height: 48px;
$dialog-height: 700px;
$dialog-width: 400px;

.bic-rule-tags-dialog,
.bic-rule-tags-dialog > .compact-companies-directory {
  max-height: $dialog-height;
  min-height: $dialog-height;

  max-width: $dialog-width;
  min-width: $dialog-width;
}

.ball-in-court-rules-table {
  .bic-rules-header {
    .bic-number-col {
      min-width: $bic-min-width-height;
    }
    .bic-rule-col {
      min-width: 360px;
    }
  }

  .bic-rules-table-row {
    padding-right: 35px;

    .bic-rule-selector-row {
      height: $bic-min-width-height;

      .bic-step-selector {
        i {
          width: 21px;
          height: 20px;
        }

        select {
          border: none !important;
          background: transparent !important;
          color: inherit !important;
          font-weight: 600;
        }
      }
    }

    .bic-rule-label {
      height: $bic-min-width-height;
    }

    .bic-assigned-user-row {
      min-height: $bic-min-width-height;
    }

    .user-profile-preview {
      .name-row {
        font-weight: 600 !important;
      }
      .brief-info-row {
        font-size: 12px !important;
        white-space: normal !important;
      }
    }

    .bic-user-selector {
      z-index: 100;
      min-width: 375px;
      max-width: 100%;
      max-height: 400px;
      bottom: $bic-min-width-height;

      .search-bar-container {
        background: get_color('neutral', 100) !important;
        padding: 5px !important;
      }

      .users-group-by {
        top: 55px;
        right: 5px;
        z-index: 4;
      }
    }

    .bic-rule-action-btns {
      .bic-duplicate-btn {
        right: 15px;
        transform: scale(-1, 1);
      }
      .bic-delete-btn {
        right: 15px;
      }
    }

    .bic-rule-category-row {
      .bic-rule-category-col {
        .bic-rule-category-icon,
        .bic-rule-category-icon svg {
          width: 16px;
          height: 16px;
          fill: get_color('neutral', 30);

          path,
          g rect {
            fill: get_color('neutral', 30);
          }
        }
      }

      .category-type-name {
        background: get_color('grey', 96);
      }

      &.location-sel,
      &.company-sel {
        .expr-element .hierarchy-delivery-attribute-tag {
          height: 24px;
          max-width: 260px;
        }
      }

      &.company-sel {
        .bic-rule-companies-selector {
          width: 300px;
          max-height: 350px;
          z-index: 3;
          background-color: get_color('neutral', 100);
          border-radius: 0 0 4px 4px;
          box-shadow: 0 4px 5px 0 get_color('neutral', 84);
        }
      }
    }
  }

  .bic-add-rule-btn span,
  .bic-add-user-btn span {
    height: $bic-min-width-height;
  }
}
