@import '~/client/src/shared/theme.module';

%bold-font {
  font-weight: 600;
}

.project-members {
  &-header {
    padding: 10px;
    margin-bottom: 5px;
    @extend %bold-font;
  }
  &-filters {
    margin-bottom: 40px;
    margin-top: 10px;
    &-title {
      @extend %bold-font;
      font-size: 16px;
    }
  }
  > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  &-user-name {
    display: inline-flex;
    &-edit-icon {
      display: none;
    }
    &:hover &-edit-icon {
      display: block;
    }
  }
  &-company {
    @extend %bold-font;
    font-size: 14px;
    color: get_color('primary', 30);
    margin-top: 15px;
    .project-members-add-member-btn + & {
      margin-top: 40px;
    }
  }
  &-add-member-row {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      &-icon {
        color: get_color('primary', 30);
        margin-right: 10px;
      }
      &-label {
        font-size: 15px;
        color: get_color('primary', 30);
      }
    }
  }
}

.project-members-upload-table-container {
  .ReactVirtualized__Table {
    max-width: fit-content;
    margin: auto;
    &__Grid {
      outline: none;
      overflow-y: scroll !important;
    }
    &__rowColumn {
      height: 100%;
      margin: 0 !important;
    }
    &__row {
      &:hover {
        background-color: get_color('primary', 92, 0.5);
      }
    }
  }
  .selector-pop-up {
    position: absolute;
    left: 24px;
    top: 21px;
    width: 200px;
    background-color: get_color('neutral', 100);
    border-radius: 0 4px 4px 4px;
    z-index: 1;
  }
  .header-row {
    width: 1430px;
    div {
      height: 100%;
    }
  }

  .company-contactor-group-cell {
    position: absolute;
    z-index: 10;

    span {
      white-space: nowrap;
      padding: 0 2px;
    }
  }
}

.tags-cell-container {
  &.plural .tag-text {
    max-width: 45px;
  }

  &.singular .tag-text {
    max-width: 100px;
  }
}

.to-company-icon {
  color: get_color('primary', 50);
}

.company-card-btn {
  max-width: 0px;
  margin-top: 80px;
}
