@import '~/client/src/shared/theme.module';

$input-width: 335px;
$result-row-width: 340px;
$input-width-small: 225px;
$closure-date-row-width: 490px;
$sitemap-selector-modal-width: 660px;

.closure-date-row {
  max-width: $closure-date-row-width;
  min-width: $closure-date-row-width;
}
.closure-date-label {
  overflow: auto;
  max-width: 500px;
  max-height: 170px;
  min-width: 170px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: get_color('neutral', 92);
  }

  &::-webkit-scrollbar-thumb {
    background: get_color('neutral', 50);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: get_color('grey', 30);
  }
}

.sitemap-selector {
  .main-block {
    flex-basis: 480px;

    .blue-icon path {
      fill: get_color('primary', 30);
    }
  }

  &-modal {
    width: $sitemap-selector-modal-width;
    background-color: get_color('neutral', 100);
    position: sticky;
    margin: auto;
    top: 200px;
    border: 1px solid get_color('neutral', 84);
    box-shadow: 0px 20px 20px -20px get_color('neutral', 0, 0.4);

    &-dimmer {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      background-color: get_color('neutral', 100, 0.3);
    }
  }
}

.section-settings-block {
  display: flex;

  &.key-section {
    height: 32px;
    align-items: center;

    i.section-icon {
      height: 20px;
      width: 20px;

      svg,
      svg g,
      svg > path {
        height: 20px;
        width: 20px;
        fill: get_color('neutral', 0);
      }
    }

    i.company-icon {
      svg > g > path {
        fill: get_color('neutral', 0);
      }
    }
  }
}

.delivery-attribute-editor {
  &-item-status {
    color: get_color('error', 50);
    padding-left: 165px;
    flex-basis: 130px;
    width: 130px;
  }

  &-input {
    display: inline-flex;
    max-width: $input-width;
    position: relative;

    &.small {
      margin-right: 10px;
      max-width: $input-width-small;
    }
    .select-chevron {
      left: 490px;
      top: 10px;
      position: absolute;
      color: get_color('primary', 30);
      pointer-events: none;
    }
  }
  i {
    cursor: pointer;
  }
  &-result-row {
    min-width: $result-row-width;
    max-width: $result-row-width;

    .sitemap-items-color-picker {
      margin: 5px;

      .value-container {
        width: 57px;
        border-radius: 2px;

        .color-option {
          flex-basis: 30px;
          height: 20px;
          border-radius: 2px;

          &.opacity-background {
            width: 30px;
            border-radius: 2px;
          }
        }

        .select-arrow-down svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    .result-row-name {
      vertical-align: middle;
      margin-right: 5px;
      max-width: 180px;
      display: inline-block;
    }
  }
  .add-icon {
    svg g > path + path {
      fill: get_color('primary', 50);
      stroke: get_color('primary', 50);
    }
  }
  &-schedule-button {
    color: get_color('primary', 30);

    display: inline-block;
    border-radius: 9px;
    height: 22px;
    padding: 0 5px;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid transparent;
    background-color: transparent;
    &:hover {
      border: 1px solid get_color('primary', 30);
    }
    &:active {
      background-color: get_color('primary', 88, 0.5);
      border: 1px solid get_color('primary', 30);
    }
  }
}

.delivery-details-configuration-header {
  margin-right: 200px;
  min-width: 136px;
}
