@import '~/client/src/shared/theme.module';

$parameter-block-width: 590px;
$parameter-block-input-width: 350px;
$banner-height: 156px;
$bp3-popover-wrapper-size: 20px;

%main-font {
  font-size: 16px;
}
%main-bold-font {
  font-weight: 600;
  font-size: 16px;
}
%main-bold-font-big {
  font-weight: 600;
  font-size: 18px;
}
%main-bold-font-medium {
  font-weight: 600;
  font-size: 17px;
}
%main-bold-font-small {
  font-weight: 600;
  font-size: 14px;
}
%main-bold-font-extra-small {
  font-weight: 600;
  font-size: 10px;
}

%main-bold-font-extra-big {
  font-weight: 600;
  font-size: 20px;
}

%main-font-small {
  font-size: 14px;
}
%main-font-extra-small {
  font-size: 11px;
}
%main-bold-font-small {
  font-weight: 600;
  font-size: 14px;
}
%main-font-tiny {
  font-size: 10px;
}
%save-button {
  border-radius: 2px;
  background-color: get_color('neutral', 100);
  color: get_color('primary', 30);
  border: 1px solid get_color('primary', 30);
}

.check-icon {
  width: 20px;
  height: 20px;
  background-image: url('/static/icons/ic-success.svg');
}

.project-type-setup {
  .primary-blue-switch {
    margin: 0;
  }
}

.profile-fields-holder {
  .bp3-file-input {
    padding-left: 65px;
  }
}

.profile-fields-holder {
  .bp3-file-input {
    padding-left: 65px;
  }
}

.project-setup {
  &-container {
    height: 100%;
    display: flex;
    width: 100%;
    min-width: 1000px;
    margin: auto;
    justify-content: space-between;
  }

  &-leftbar-header {
    background-color: get_color('neutral', 30);
    border-right: 1px solid get_color('neutral', 84);
    &.hidden-panel {
      padding-left: 13px;
    }
    .menu-toggle-icon {
      svg {
        height: 18px;
        width: 18px;
        fill: get_color('neutral', 100);
      }
    }
  }

  &-leftbar {
    height: calc(100% - 38px);
    background-color: get_color('neutral', 30);
    width: $SETTINGS-LEFT-SIDEBAR-WIDTH;
    flex-shrink: 0;
    border-right: 1px solid get_color('neutral', 84);
    transition: width 0.2s;

    .menu-toggle-icon svg path {
      fill: get_color('neutral', 100);
    }
    .menu-toggle-icon-holder {
      justify-content: flex-start;
    }
    .navigation-group-header-separator {
      display: none;
    }
    &.short {
      .menu-toggle-icon-holder {
        display: flex !important;
      }
      .navigation-group-header-separator {
        display: block !important;
      }
      transition: width 0.2s;
      width: fit-content !important;
      .navigation-row-title {
        padding-left: 10px !important;
        .title-text {
          display: none;
        }
        i {
          margin-right: 6px !important;
        }
      }
      .user-profile-preview {
        padding: 5px !important;
        .user-profile-preview-additional-info {
          display: none;
        }
      }
      .navigation-group-header {
        display: none !important;
      }
      .client-info {
        display: none;
      }
    }
    .user-info {
      &-title {
        @extend %main-font-extra-small;
        margin-bottom: 3px;
      }

      &-content {
        margin-bottom: 20px;
        @extend %main-font-small;

        &-name {
          @extend %main-bold-font-medium;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: get_color('neutral', 100);
        }
      }
    }

    .navigation-group {
      margin-bottom: 10px;
      user-select: none;
      word-break: break-word;

      &-header {
        @extend %main-bold-font-extra-small;
        padding: 16px 0 8px 0;
        color: get_color('neutral', 100, 0.26);

        &.title {
          @extend %main-bold-font-extra-small;
          text-transform: uppercase;
          letter-spacing: 1.5px;
          padding-left: 16px;
        }

        .side-bar-icon {
          color: get_color('neutral', 50) !important;
          cursor: pointer;
          svg {
            transition-duration: 0.5s;
          }

          &.rotate {
            svg {
              transform: rotate(180deg);
            }
          }
          &.disable {
            display: none;
          }
        }
      }

      .user-profile-preview {
        padding-left: 16px;

        .avatar-container .initials {
          word-break: normal;
        }

        .name-row {
          @extend %main-bold-font-small;
          letter-spacing: 0.15px;
          color: get_color('neutral', 100);
        }

        .brief-info-row .brief-info-company,
        .brief-info-row .brief-info-roles {
          font-size: 12px;
          letter-spacing: 0.5px;
          color: get_color('neutral', 100, 0.26);
        }
      }

      .navigation-row {
        &-title {
          @extend %main-font-small;
          color: get_color('neutral', 100);
        }

        .navigation-row-title {
          outline: none !important;
          i {
            height: 24px !important;
            width: 24px !important;
            margin-right: 16px;

            svg {
              height: 18px;
              width: 18px;
              fill: get_color('neutral', 100);

              g,
              rect {
                fill: get_color('neutral', 100);
              }
            }
          }
        }

        &.current {
          cursor: default !important;
          background-color: get_color('primary', 96);

          .navigation-row-title {
            @extend %main-bold-font-small;
            color: get_color('neutral', 0);

            i {
              mask,
              use {
                fill: get_color('neutral', 0);
              }
              svg path {
                fill: get_color('neutral', 0);
              }
            }
          }
        }
      }
    }
  }

  &-rightbar {
    width: 400px;
    flex-shrink: 0;
    padding: 25px 0;
    border-left: 1px solid get_color('neutral', 84);
    &:empty {
      border: 0;
    }
  }

  &-mainframe {
    min-width: 700px;
    position: relative;
    right: 0;
    width: 100%;
    background-color: get_color('neutral', 100);

    &.min-width-unset {
      min-width: unset !important;
    }

    .menu-icon {
      path {
        fill: get_color('neutral', 60);
      }
    }
  }
}

.status-icon {
  width: 16px;
  height: 16px;
  visibility: hidden;
}

.project-details {
  .setup-form-header {
    padding: 0 !important;
    margin: 55px 0 27px;
  }
}
.setup-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  &-fixed-width {
    width: 600px;
  }

  &-header {
    @extend %main-bold-font-big;
    color: get_color('neutral', 0);
    padding: 55px 0 12px;
  }

  &-body {
    display: flex;
    flex-direction: column;
  }

  &-footer {
    display: flex;
    flex-direction: column;
    position: sticky;
    bottom: 0;
    background-color: get_color('neutral', 100);
    border-top: solid 1px get_color('grey', 88);
    margin-left: -20px;
  }

  &-parameter {
    display: flex;
    justify-content: flex-start;
    width: 500px;

    .full-block-width {
      width: $parameter-block-input-width;
      margin-bottom: 12px;
    }

    .address-container {
      display: flex;
      justify-content: space-between;

      .country {
        width: 251px;
      }

      .state,
      .zip-code {
        width: 78px;
        margin-right: 10px;
      }
    }

    .parameter-label {
      padding-right: 10px;
      margin-top: 12px;
      color: get_color('neutral', 0);
      width: 200px;
      margin-left: 40px;
      .bp3-popover-wrapper {
        max-height: $bp3-popover-wrapper-size;
        i svg {
          width: $bp3-popover-wrapper-size;
          height: $bp3-popover-wrapper-size;
        }
      }
    }

    .parameter-value {
      max-width: $parameter-block-width;

      .error-label {
        @extend %main-font-tiny;
        color: get_color('error', 50);
      }

      .bp3-active button {
        padding-bottom: 0;
        margin-top: 0;
      }

      .bp3-button {
        height: 40px;
        outline: none;
        border-radius: 4px;
        padding: 7px 8px;
        background: get_color('neutral', 96);
        box-shadow: none;
        border: 1px solid get_color('neutral', 84) !important;

        .bp3-button-text,
        .bp3-button-text > span {
          font-size: 16px;
          line-height: 24px;
          margin: 0 !important;
        }
      }

      & > span {
        @extend %main-font-small;
        display: block;
        color: get_color('neutral', 50);
        margin: 10px 0 0 10px;
      }
    }
  }

  &-controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-controls.submit-button-container {
    .bp3-button.primary-blue-btn {
      @extend %main-bold-font;
      height: 40px;
      margin: 20px;

      &:hover {
        background-color: intial;
      }
    }
  }

  &-controls.done-button-container {
    .bp3-button.primary-blue-btn {
      @extend %main-bold-font;
      margin: 5px 0 0 15px;
      height: 5px;

      &:hover {
        background-color: intial;
      }
    }
  }
}

.upload-team {
  &-container {
    height: auto;
    display: flex;
    justify-content: space-between;
  }

  &-block {
    width: 244px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    &-bordered {
      border: 2px dashed get_color('primary', 30);
    }
  }
  &-download {
    @extend %main-bold-font;
    color: get_color('primary', 30);
    &:before {
      background-image: url('/static/icons/download.svg');
      width: 20px;
      height: 20px;
      position: absolute;
      left: -25px;
      display: block;
      content: '';
    }
  }
  &-note {
    @extend %main-font-small;
    color: get_color('neutral', 50);
    line-height: 1.6;
  }
  &-separator {
    align-self: center;
    font-weight: 600;
    font-size: 24px;
    padding: 10px;
  }
}

.upload-schedule {
  &-container {
    display: flex;
    justify-content: space-between;
  }
  &-note {
    width: 260px;
    color: get_color('neutral', 50);
    line-height: 1.6;
  }
  &-gantt-note {
    margin: 0 auto;
    color: get_color('neutral', 50);
  }
  &-verification-row {
    display: flex;
    margin-top: 20px;
    color: get_color('neutral', 50);
  }
  &-verification-count {
    width: 50px;
    text-align: right;
    margin-right: 10px;
  }
  &-verification-details {
    margin-left: 10px;
  }
  &-dnd-tip {
    position: absolute;
    top: 5px;
    right: 5px;
    color: get_color('primary', 30);
  }
  &-link {
    font-weight: 600;
    color: get_color('primary', 30);
    cursor: pointer;
    text-decoration: none !important;
  }
  &-continue {
    text-align: right;
  }

  &-white-btn {
    background-color: get_color('neutral', 100);
    border: 1px solid get_color('primary', 30);
    color: get_color('primary', 30);
    border-radius: 3px;
    padding: 5px 30px;
    box-shadow: none;
    cursor: pointer;
  }
}

.admin-summary {
  &-container {
    width: 550px;
    margin-left: 40px;
  }

  &-header {
    @extend %main-bold-font-extra-big;
    padding: 55px 0 12px;
    border-bottom: 1px solid get_color('neutral', 84);
  }

  &-content-block {
    display: flex;
    padding-top: 30px;

    &-icon {
      cursor: pointer;
      width: 95px;

      span {
        display: inline-block;

        &.project-icon {
          width: 48px;
          height: 49px;
          background: url('/static/icons/project-details-icon.svg');
        }

        &.schedule-icon {
          width: 51px;
          height: 46px;
          background: url('/static/icons/upload-schedule-icon.svg');
        }

        &.procurment-plan-icon {
          width: 57px;
          height: 49px;
          background: url('/static/icons/upload-procurment-plan-icon.svg');
        }

        &.team-list-icon {
          width: 48px;
          height: 48px;
          background: url('/static/icons/upload-team-list-icon.svg');
        }
      }
    }

    &-info {
      width: 451px;
      padding-bottom: 22px;
      border-bottom: 1px solid get_color('neutral', 84);

      &-header {
        @extend %main-bold-font-small;
        color: get_color('primary', 30);
        margin-bottom: 8px;
        cursor: pointer;
      }

      &-paragraph {
        @extend %main-font-small;
        color: get_color('neutral', 50);
        margin-bottom: 8px;
        white-space: pre-line;
      }

      &-paragraph-header {
        @extend %main-bold-font-small;
        color: get_color('neutral', 0);
        margin-bottom: 0;
      }
    }
  }
}

.workflows-setup {
  height: 100%;

  &-header {
    padding: 12px 0;

    .title {
      @extend %main-bold-font-extra-big;
    }
  }
  &-container {
    display: flex;
    height: 100%;
  }
  &-main {
    width: 100%;
    padding: 0 35px 50px;

    .form-setup {
      padding: 45px 0 20px;
    }
    .loader {
      height: calc(100% - #{$banner-height});
    }
  }
}
