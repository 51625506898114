@import '~/client/src/shared/theme.module';

.activity-filters {
  &-btns {
    .selected-codes {
      padding-left: 60px;
      max-width: 500px;
    }
    &-item {
      width: 135px;
      border: 1px solid get_color('primary', 30);

      &-blue.bp3-button {
        color: get_color('neutral', 100);
        background: get_color('primary', 30);
        &:hover {
          background: get_color('primary', 30);
        }
      }
      &-white.bp3-button {
        color: get_color('primary', 30);
        box-shadow: none;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}
.activity-filters-step-description {
  .step {
    flex: 0 0 60px;
  }
}

.filter-name-tab-caption {
  cursor: pointer;
  font-size: 16px;
  max-width: 250px;

  .edit-caption-input {
    text-align: center;
    border: none;
    color: get_color('primary', 50);
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 4px 5px 0 get_color('neutral', 50),
      0 1px 10px 0 get_color('neutral', 50),
      0 2px 4px -1px get_color('neutral', 50);
    outline: none;
    bottom: 0px;
  }

  &.add-new-filter {
    color: get_color('primary', 50);
  }

  &.set {
    color: get_color('neutral', 50);
  }

  &.active {
    background-color: get_color('primary', 96);
    border-radius: 6px 6px 0 0;
    color: get_color('primary', 50);
    border-bottom: 2px solid get_color('primary', 50);
  }
  &.disabled {
    opacity: 0.5;
  }
}

.mappable-filter-tab-content {
  max-width: 1000px;

  .blue-icon {
    path {
      fill: get_color('primary', 50);
    }
  }
}

.mapping-selector {
  .mapping-select {
    display: flex;

    .bp3-popover-target {
      width: 100%;
    }
  }

  &-input {
    white-space: nowrap;
    height: 48px;

    .bp3-icon {
      path {
        fill: get_color('neutral', 0);
      }
    }
  }

  &-popover {
    width: 400px;

    .bp3-active {
      background-color: inherit !important;
      color: inherit !important;
    }

    .bp3-menu {
      overflow: auto;
      max-height: 400px;
    }
  }
}

.blue-icon path {
  fill: get_color('primary', 50);
}

.activity-filter-setup-dialog.bp3-dialog {
  border: 1px solid get_color('neutral', 84);
  border-radius: 1px;
  background-color: get_color('neutral', 100);
  width: 560px;
  height: 700px;
  padding: 0;
  box-shadow: none;

  .bp3-dialog-header {
    .bp3-heading {
      font-size: 16px;
    }
  }

  .bp3-dialog-close-button {
    .bp3-icon {
      color: get_color('primary', 30);
      transform: scale(1.4);
    }
  }

  .bp3-dialog-body {
    padding: 20px 30px;
    overflow: auto;
    margin: 0;
  }

  .bp3-dialog-footer {
    margin: 0;

    .bp3-dialog-footer-actions {
      border-top: 1px solid get_color('neutral', 84);
      justify-content: space-between;
      padding: 10px 30px;
    }
  }
}
