@import '~/client/src/shared/theme.module';

$category-row-height: 30px;

.compact-deliveries-list {
  .category {
    background-color: get_color('neutral', 0);
    min-height: $category-row-height;
    max-height: $category-row-height;

    svg {
      color: get_color('neutral', 60);
    }
  }

  .card-wrapper {
    &.highlight {
      background-color: get_color('primary', 96);
    }
    &.active-delivery {
      outline: 2px solid get_color('primary', 50) !important;
      outline-offset: -2px !important;
    }
  }
}
