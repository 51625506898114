.editable-text-column {
  .save-icon {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .edit-icon {
    display: none;
    svg {
      width: 12px;
      height: 12px;
    }
  }

  &:hover {
    .edit-icon {
      display: inline-block;
    }
  }
}
