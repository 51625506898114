$sidebar-width: 351px;

.desktop-deliveries .deliveries-map-action-bar {
  .new-delivery-btn,
  .sidebar {
    z-index: 11;
  }

  .sidebar {
    max-width: $sidebar-width;
    min-width: $sidebar-width;

    .base-action-btn-title {
      max-width: 140px;
    }
  }
}
