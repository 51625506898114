@import '~/client/src/shared/theme.module';

.analytics-view {
  &-tab {
    min-width: 170px;
    max-width: 250px;
    height: 32px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 2px solid transparent;

    &.active {
      background-color: get_color('primary', 96);
      border-bottom-color: get_color('primary', 50);
      color: get_color('primary', 50);
      font-weight: 600;

      span {
        color: get_color('primary', 50);
      }
    }
  }
}
