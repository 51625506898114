@import '~/client/src/shared/theme.module';

.purple-confirm {
  background-color: get_color('primary', 20);
  border-radius: 10px;
  padding: 15px 10px;
  position: absolute;
  width: 360px;
  left: calc(50% - 180px);
  top: 70px;
  z-index: 100;

  &-cross {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;

    svg {
      height: 22px;
      width: 22px;

      path:last-child {
        fill: get_color('neutral', 70);
      }
    }
  }

  &-header {
    color: get_color('warning', 70);
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  &-body {
    color: get_color('neutral', 100);
    font-size: 14px;
    padding-bottom: 10px;
  }

  button {
    color: get_color('primary', 30);
    background-color: get_color('neutral', 100);
    font-size: 14px;
    font-weight: 600;
    padding: 5px 20px;
    border: none;
    cursor: pointer;
  }
}
