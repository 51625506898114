@import '~/client/src/shared/theme.module';

$header-height: 47px;
$default-icon-size: 16px;
$checkbox-col-index: 1;
$permit-col-index: 2;
$text-letter-height: 1.08;
$text-letter-spacing: 0.5px;

.logistics .table-holder,
.forms .table-holder {
  &.permits-list .logistics-list-table .ReactVirtualized__Table__row {
    cursor: pointer;

    .workflow-card-status {
      margin: 0 !important;
      padding: 2px 4px !important;
    }
  }

  .cell-header {
    white-space: pre;
  }
}

.logistics .table-holder .logistics-list-table,
.forms .table-holder .logistics-list-table {
  display: flex;
  height: 100%;

  .select-button {
    margin: 0px;
    .bp3-icon svg {
      fill: get_color('neutral', 50);
    }
  }

  .p-checkbox {
    padding: 0 !important;
    justify-content: center;
  }

  .p-checkbox-box {
    background: get_color('neutral', 100);
    border-color: get_color('neutral', 50) !important;
    color: get_color('neutral', 50);
  }

  .checkbox-no-right-border {
    border-right: none !important;
  }

  .company-icon,
  .company-icon svg {
    width: $default-icon-size;
    height: $default-icon-size;
  }

  .company-item-label {
    height: 32px;
    min-width: 112px;
    max-width: 112px;
  }

  .location-status-text {
    font-stretch: normal;
    font-style: normal;
    line-height: $text-letter-height;
    letter-spacing: $text-letter-spacing;
  }

  .location-parents-row {
    height: 13px;
    line-height: $text-letter-height;
    letter-spacing: $text-letter-spacing;
  }

  .logistics-list-category-label {
    margin-left: -8px;
  }

  .ReactVirtualized {
    &__Table {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 100%;
      &__row:hover {
        box-shadow: none;
      }
      &__headerRow {
        width: 100%;
        align-items: flex-end;
        min-height: $header-height;
        max-height: $header-height;
        position: sticky;
        top: 0;
        background-color: get_color('neutral', 100);
      }
      &__headerColumn {
        margin-right: 0;
        padding-top: 0;

        &:nth-child(#{$checkbox-col-index}) > div {
          padding: 0;
        }
      }
      &__rowColumn {
        display: flex;
        align-items: center;
        justify-content: left;
        margin-right: 0;
        overflow: visible !important;

        &:nth-child(#{$checkbox-col-index}) > div {
          padding: 0;
        }

        &:nth-child(#{$permit-col-index}) > div {
          padding: 0 12px 0 0;
        }
      }
      &__headerColumn,
      &__rowColumn {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
    &__Grid {
      width: 100%;
      outline: none;
      overflow: hidden auto !important;
      height: unset !important;
    }
    &__Grid__innerScrollContainer {
      width: 100% !important;
      max-width: unset !important;
    }
  }

  i.permit-type-icon,
  i.permit-type-icon svg {
    height: 20px;
    width: 20px;
  }
}
