@import '~/client/src/shared/theme.module';

.schedule-update-summary {
  width: 600px;
  border: 2px solid get_color('neutral', 50);
  border-radius: 3px;
  background-color: get_color('neutral', 100);

  .bp3-dialog-header {
    .bp3-heading {
      color: get_color('neutral', 0);
      padding: 15px 0;
    }
    .bp3-dialog-close-button {
      display: none;
    }
  }
  &-table {
    padding: 30px;
  }
  &-row {
    padding: 2px 0;
    color: get_color('neutral', 50);
    &-header {
      font-weight: 600;
      color: get_color('neutral', 0);
    }
  }
  &-separator {
    border-width: 0;
    border-bottom: 1px solid get_color('grey', 84);
  }
  &-col {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-name {
      width: 160px;
      padding-right: 10px;
    }
  }
  .bp3-dialog-footer-actions {
    justify-content: center;
    padding: 0 20px 15px 10px;
  }
  &-btn-cancel {
    &.bp3-button {
      width: 150px;
      border: 1px solid get_color('primary', 30);
      color: get_color('primary', 30);
      background: get_color('neutral', 100);
      box-shadow: none;
    }
  }
  &-btn {
    &.bp3-button {
      width: 150px;
      background: get_color('primary', 30);
      color: get_color('neutral', 100);
      box-shadow: none;
    }
  }

  .compare-container {
    text-align: center;
    .compare-header-line {
      font-size: 14px;
      font-weight: 600;
      padding: 15px 0 10px 0;

      &:first-child {
        font-size: 15px;
        padding: 10px 0;
      }
    }
    .compare-line {
      padding: 5px 0;
      word-break: break-all;

      .current {
        color: get_color('neutral', 0);
      }
      .criterion {
        color: get_color('neutral', 50);
      }
      .uploaded {
        justify-content: center;
        color: get_color('primary', 30);

        .warning-icon {
          margin-left: 5px;
        }
      }
    }
    .warning {
      color: get_color('secondary', 40);
      padding: 20px;
    }
    .compare-act-type-codes-section {
      max-height: 270px;
      overflow-y: scroll;
    }
    .loading-area {
      margin: 20px;
    }
  }

  .view-gantt-btn {
    border: none;
    outline: none;
    color: get_color('primary', 30);
    cursor: pointer;
    font-weight: 600;
    margin-left: 160px;
  }
}
