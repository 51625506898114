@import '~/client/src/shared/theme.module';

.objects-list {
  .announcement-creation-form {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    border: 1px solid get_color('neutral', 84);
    background-color: get_color('neutral', 100);
    z-index: 125;

    .announcement-creation-form-content {
      height: calc(100% - 40px) !important;
    }
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  .TopRightGrid_ScrollWrapper {
    .ReactVirtualized__Grid__innerScrollContainer {
      display: flex;
      flex-direction: row;
    }
  }
  .header-row-cell {
    height: 43px !important;
  }
  .editable-label {
    min-width: 160px;
  }
  .bp3-icon-chevron-right {
    top: 35px !important;
    svg path {
      fill: get_color('primary', 30);
    }
  }
  .bp3-icon-chevron-left {
    top: 35px !important;
    left: 185px !important;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: get_color('primary', 30);
      }
    }
  }
  .sorted {
    background-color: get_color('primary', 96);
  }
  .category-row {
    .hierarchy-delivery-attribute-tag {
      height: 100%;
    }
  }

  .permit-sidebar-holder {
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0;
    z-index: 10;
    background-color: get_color('neutral', 100);
    outline: 1px solid get_color('neutral', 84) !important;

    .ios-scrolling-container {
      .compact-popup-modal.shown {
        height: calc(100% - 25px);
      }
      .dimmer.shown {
        height: 100%;
      }
    }

    .activity-log-entry-left-col {
      min-width: 90px;
    }
  }
}
