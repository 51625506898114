@import '~/client/src/shared/theme.module';

.presentation-screen {
  padding: 10px 10px 50px 10px;
  background-color: get_color('neutral', 100);

  img {
    pointer-events: none;
    width: 100%;
    border: solid 1px get_color('neutral', 70);
  }
}
