@import '~/client/src/shared/theme.module';

.delivery-materials-cell-modal {
  &.bp3-popover,
  .bp3-popover-content {
    border-radius: 8px !important;
  }

  .materials-modal-content {
    max-height: 260px;

    .material-tag {
      max-width: 130px;
    }
  }
}
