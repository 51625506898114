@import '~/client/src/shared/theme.module';

.gantt-options-container {
  top: 30px;
  z-index: 101;
  left: -1px;
  box-shadow: 0 14px 28px get_color('neutral', 0, 0.25),
    0 10px 10px get_color('neutral', 0, 0.25);
  .bp3-icon {
    padding: 0 5px;
  }
  .option {
    height: 32px;
    &:hover {
      background-color: get_color('primary', 90);
    }

    &:active,
    &.selected {
      color: get_color('neutral', 100);
      border-color: get_color('primary', 30);
      background-color: get_color('primary', 30);
    }
  }
}

.new-activity-btn {
  width: 136px;
  height: 32px;

  &.active,
  &:hover {
    color: get_color('primary', 30);

    svg path {
      stroke: get_color('primary', 30);
      fill: get_color('primary', 30);
    }
  }
}

.activities-view {
  .overflowed-filters-list {
    min-width: 200px;
  }
  .overflowed-filters-modal {
    max-height: 480px;
    max-width: 350px;
    z-index: 100;
  }
}
