@import '~/client/src/shared/theme.module';

.analytics-setting .setup-form-parameter {
  width: 620px;

  .primary-blue-switch {
    padding-left: 0;
  }
}

.analytics-setting-sidebar {
  right: 0;
  top: 0;
  border-left: 1px solid get_color('neutral', 84);
  z-index: 100;
  width: 450px;
  min-height: calc(100vh - 49px);
  height: 100%;

  .summary-actions {
    svg {
      width: 18px;
      height: 18px;

      path {
        fill: get_color('neutral', 10);
      }
    }
  }
}

.summarized-column-form
  .setup-form-controls.submit-button-container
  .bp3-button.primary-blue-btn {
  margin: 0;
  height: 35px;
}
