@import '~/client/src/shared/theme.module';

.number-selector {
  display: inline-block;
  padding-right: 15px;
  position: relative;

  .increment-arrow,
  .decrement-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    right: 2px;
    cursor: pointer;
  }

  .increment-arrow {
    border-bottom: 6px solid get_color('neutral', 70);
    top: 2px;
  }

  .decrement-arrow {
    border-top: 6px solid get_color('neutral', 70);
    bottom: 2px;
  }
}
