@import '~/client/src/shared/theme.module';

.add-another-label {
  color: get_color('neutral', 60);
}

.parent-selector-modal {
  height: 400px;
  width: 300px;
  background-color: get_color('neutral', 100);
  border-radius: 4px;
  box-shadow: 0 20px 50px -20px get_color('neutral', 50);
}
