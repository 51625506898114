@import '~/client/src/shared/theme.module';

.plus-container {
  display: inline-flex;

  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;

  background-color: get_color('neutral', 96);

  svg {
    fill: get_color('neutral', 0);
  }

  &:hover {
    background-color: get_color('neutral', 84);
  }

  .pull-down {
    z-index: 3;

    top: 100%;
    background-color: get_color('neutral', 100);
    min-width: 150px;

    &-row:hover {
      background-color: get_color('neutral', 96);
    }
  }
}
