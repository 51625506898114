@import '~/client/src/shared/theme.module';

$header-height: 47px;
$transition-time: 0.2s;
$collapse-buttons-bar-height: 20px;

$booking-section-count: 4, 5, 6;
$locations-count: 0, 1, 2, 3, 4, 5, 6;
$equipment-and-materials-count: 0, 1, 2;

@mixin collapsed-cell($col-index) {
  .ReactVirtualized__Table__headerColumn:nth-child(#{$col-index}) {
    border-bottom: 1px solid get_color('neutral', 0);
  }
  .ReactVirtualized__Table__rowColumn:nth-child(#{$col-index}) {
    border-bottom: 1px solid get_color('neutral', 84);
  }
  .ReactVirtualized__Table__headerColumn:nth-child(#{$col-index}),
  .ReactVirtualized__Table__rowColumn:nth-child(#{$col-index}) {
    max-width: 19px !important;
    min-width: 19px !important;
    .ReactVirtualized__Table__headerTruncatedText {
      display: none;
    }
  }
}

@mixin collapsed-sections(
  $duration-col-index,
  $building-zone-col-index,
  $equipment-col-index,
  $materials-col-index,
  $vendor-col-index
) {
  &.collapsed-duration {
    @include collapsed-cell($duration-col-index);
  }
  &.collapsed-locations {
    @include collapsed-cell($building-zone-col-index);
  }
  &.collapsed-equipment {
    @include collapsed-cell($equipment-col-index);
  }
  &.collapsed-materials {
    @include collapsed-cell($materials-col-index);
  }
  &.collapsed-vendor {
    @include collapsed-cell($vendor-col-index);
  }

  .ReactVirtualized {
    &__Table {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 100%;
      &__headerRow {
        width: 100%;
        align-items: flex-end;
        min-height: $header-height;
        max-height: $header-height;
        font-weight: unset;
        position: sticky;
        top: 0;
        background-color: get_color('neutral', 100);
      }
      &__headerColumn {
        margin-right: 0;
        padding-top: 0;
      }
      &__rowColumn {
        display: flex;
        align-items: center;
        justify-content: left;
        margin-right: 0;
        overflow: visible !important;
      }
      &__headerColumn,
      &__rowColumn {
        &:first-of-type {
          margin-left: 0;
        }
        &:nth-child(#{$duration-col-index}),
        &:nth-child(#{$building-zone-col-index}),
        &:nth-child(#{$equipment-col-index}),
        &:nth-child(#{$materials-col-index}),
        &:nth-child(#{$vendor-col-index}) {
          border-left: 1px solid get_color('neutral', 84);
        }
        &:last-of-type {
          border-right: 1px solid get_color('neutral', 84);
        }
      }
    }
    &__Grid {
      width: 100%;
      outline: none;
      overflow: hidden auto !important;
      margin-top: $collapse-buttons-bar-height;
      height: unset !important;
    }
    &__Grid__innerScrollContainer {
      width: 100% !important;
      max-width: unset !important;
    }
  }
}

.deliveries-list-container .table-holder .deliveries-collapsible-columns-table {
  display: flex;
  height: 100%;

  .material-tag {
    max-width: 130px;
  }

  @mixin locations($duration-col-index, $building-zone-index) {
    @each $loc-element in $locations-count {
      &.locations-count-#{$loc-element} {
        $equipment-index: $building-zone-index + $loc-element;

        @include equipments(
          $duration-col-index,
          $building-zone-index,
          $equipment-index
        );
      }
    }
  }

  @mixin equipments(
    $duration-col-index,
    $building-zone-index,
    $equipment-index
  ) {
    @each $eq-element in $equipment-and-materials-count {
      $materials-index: $equipment-index + $eq-element;

      &.equipments-count-#{$eq-element} {
        @include materials(
          $duration-col-index,
          $building-zone-index,
          $equipment-index,
          $materials-index
        );
      }
    }
  }

  @mixin materials(
    $duration-col-index,
    $building-zone-index,
    $equipment-index,
    $materials-col-index
  ) {
    @each $matr-element in $equipment-and-materials-count {
      &.materials-count-#{$matr-element} {
        $vendor-col-index: $materials-col-index + $matr-element;

        @include collapsed-sections(
          $duration-col-index,
          $building-zone-index,
          $equipment-index,
          $materials-col-index,
          $vendor-col-index
        );
      }
    }
  }

  @each $book-element in $booking-section-count {
    &.booking-section-count-#{$book-element} {
      $duration-col-index: $book-element + 1;
      $building-zone-index: $duration-col-index + 1;

      @include locations($duration-col-index, $building-zone-index);
    }
  }

  .collapse-buttons-bar {
    top: $header-height;
    z-index: 10;
    height: $collapse-buttons-bar-height;
    border-bottom: 1px solid get_color('neutral', 0);

    .collapse-btn {
      position: absolute;
      top: 2px;
      .arrow-right {
        display: none;
      }
      &.section-collapsed {
        .arrow-left {
          display: none;
        }
        .arrow-right {
          display: inherit;
        }
      }
    }
  }

  .collapsed-sections-holder {
    position: absolute;
    top: 0;
    z-index: 1;

    .section-title {
      transition: left $transition-time;
      line-height: 11px;
      letter-spacing: 0.3px;
      color: get_color('neutral', 0);
      width: fit-content;
      font-weight: 600;
      white-space: nowrap;
      top: 11px;
      display: flex;
      align-items: center;
      max-width: 34px;
      height: 30px;
      transform: rotate(-90deg);
      overflow: hidden;
    }
  }

  .checkbox-selector {
    .p-checkbox-box {
      background: get_color('neutral', 100) !important;
      border-color: get_color('neutral', 70) !important;
      color: get_color('neutral', 50) !important;
    }
  }

  .select-button .bp3-icon svg {
    fill: get_color('neutral', 50);
  }
}
