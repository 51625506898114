@import '~/client/src/shared/theme.module';

.rule-row-container {
  min-height: $NOTIFICATION_RULE_CONTAINERS_HEIGHT;

  .pull-wrapper {
    top: 100%;
    left: 0;

    max-height: 200px;

    z-index: 3;
    background-color: get_color('neutral', 100);
  }

  .actions-users-container {
    min-height: $NOTIFICATION_RULE_CONTAINERS_HEIGHT;

    .action-picker-col {
      min-width: 300px;
      min-height: $NOTIFICATION_RULE_CONTAINERS_HEIGHT;
    }

    .user-picker-col {
      min-width: 350px;
    }
  }

  .delete-rule-icon-container {
    left: -25px;
  }
}
