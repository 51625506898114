@import '~/client/src/shared/theme.module';

.project-members-upload-table-container {
  height: calc(100% - 40px) !important;
  overflow: auto hidden;

  .category-cell {
    background-color: get_color('neutral', 60, 0.1);
  }
}

.project-members-upload-content {
  .category-checkbox {
    display: flex;
    flex: 0 0 60px;
  }

  .options-holder {
    &.top-controls-dropdown {
      top: 25px;
      right: 50px;
    }
    position: fixed;
    z-index: 102;
    background-color: get_color('neutral', 100);
    left: 300px;
    border: 1px solid;
    .option {
      padding: 5px 10px;
      height: 40px;
      &-icon,
      .upload-button-icon {
        width: 20px;
        margin-right: 25px;
      }
      .no-icon-text {
        margin-left: 45px; // icon space
      }
      &:hover {
        background-color: get_color('primary', 80);
      }
      &.active {
        color: get_color('neutral', 100);
        background-color: get_color('primary', 30);
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    right: 0;
    top: 0;
  }
  .search-holder {
    .gant-header-btn-outline {
      border: none;
    }
  }
}
