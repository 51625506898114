@import '~/client/src/shared/theme.module';

.notification-details-content-area {
  width: 400px !important;

  .ios-scrolling-container.permit-side-view {
    .compact-popup-modal.shown {
      height: calc(100% - 25px);
    }
    .dimmer.shown {
      height: 100%;
    }
  }

  .gant-side-bar {
    &.closed {
      width: 0;
    }
    &.opened {
      position: relative !important;
    }
  }

  .activity-log-entry-left-col {
    min-width: 90px;
  }
}

.notification-details {
  &-content {
    padding: 0 66px;
  }

  .slack-bar {
    padding: 10px 66px;

    .send-button {
      background-color: get_color('neutral', 100);
      border: none;
      font-weight: 600;
      font-size: 14px;
      color: get_color('primary', 30);
      flex: none;
      margin: 0 10px;
    }

    .close-instance-btn {
      border: none;
      font-weight: 600;
      font-size: 14px;
      font-weight: normal;
      border-radius: 3px;
      color: get_color('neutral', 100);
      flex: none;
      margin: 0 20px;
      padding: 9px 35px;

      &.flag-thread {
        background-color: get_color('error', 50);
      }

      &.rfi-thread {
        background-color: get_color('error', 50);
      }

      &.schedule-comment-thread {
        background-color: get_color('error', 50);
      }
    }
  }
}

.announcement-creation-form {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
