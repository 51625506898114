@import '~/client/src/shared/theme.module';
@import '~/client/src/shared/mixins';

.bp3-overlay-backdrop {
  background-color: get_color('neutral', 100, 0.3);
}

.base-add-edit-wrapper {
  &.bp3-dialog {
    min-width: 760px;
    max-width: 760px;
    max-height: 700px;
  }

  .site-permit-input {
    height: 32px !important;
  }

  .arrow-btn,
  .arrow-btn:hover {
    width: auto;
    border: none;
    box-shadow: none;
    background-color: get_color('neutral', 100);

    path {
      fill: get_color('primary', 50);
    }
  }

  .navigation-wrapper {
    left: 313px;
  }

  background-color: get_color('neutral', 100);

  min-width: 860px;
  max-width: 860px;
  max-height: 700px;
  padding-bottom: 4px;

  &-select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
    text-transform: capitalize;
  }

  &-error {
    text-align: right;
    font-size: 12px;
    color: get_color('error', 50);
  }

  .bp3-dialog-header,
  .bp3-dialog-body,
  .bp3-dialog-footer {
    box-shadow: none;
    margin: 0;
  }

  .bp3-dialog-body {
    overflow-y: auto;
  }

  .bp3-label {
    width: 150px;
    text-align: right;
  }

  .bp3-checkbox {
    margin-left: 160px;
  }

  .hierarchy-delivery-attribute-tag {
    z-index: 0;
  }
}
