@import '~/client/src/shared/theme.module';

.tm-dp {
  &-input {
    ::placeholder {
      color: get_color('neutral', 84);
    }
    max-width: 253px;
  }
  &-container {
    width: 880px;
    padding: 10px 30px;
    background-color: get_color('neutral', 100);
    border-radius: 2px;
  }
  &-month-picker {
    width: 400px;
  }

  &-month-header {
    height: 50px;
    position: relative;
  }

  &-btn {
    &-container {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      padding: 10px;
    }

    color: get_color('primary', 30);
    font-size: 18px;
    &:hover {
      cursor: pointer;
    }
  }

  &-month-switch {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 1px solid get_color('grey', 90);
    border-radius: 2px;

    &:hover {
      cursor: pointer;
    }

    &.sw-left {
      left: 5px;

      .arrow {
        width: 0px;
        height: 0px;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right: 7px solid get_color('primary', 30);
      }
    }

    &.sw-right {
      right: 5px;

      .arrow {
        width: 0px;
        height: 0px;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid get_color('primary', 30);
      }
    }
  }
}
