@import '~/client/src/shared/theme.module';

.status-indicator {
  &.requested {
    color: get_color('neutral', 80);
  }

  &.changed,
  &.scheduled {
    color: get_color('primary', 30);
  }

  &.on-site,
  &.passed-inspection,
  &.failed-inspection,
  &.incomplete-done,
  &.done,
  &.deleted {
    color: get_color('secondary', 40);
  }

  &.submittal-in-review,
  &.submittal-complete,
  &.in-fabrication,
  &.fabrication-complete,
  &.in-storage,
  &.installed {
    color: get_color('neutral', 0);
  }
}
