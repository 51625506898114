@import '~/client/src/shared/theme.module';

$loader-offset: 70px;

.non-work-times-configurator .bp3-switch.primary-blue-switch,
.bp3-switch.primary-blue-switch.forms-multiple-toggle,
.bp3-switch.primary-blue-switch.forms-inspection-toggle,
.routing-location .bp3-switch.primary-blue-switch {
  margin-bottom: 0 !important;
  height: 22px !important;
}

.permit-settings {
  .breadcrumb-icon,
  .breadcrumb-icon svg {
    width: 20px;
    height: 20px;
  }
  .permit-types-tabs {
    margin: 70px 0 32px 0;
  }

  .forms-field-row {
    .forms-add-icon-container {
      left: -10px;
    }

    $add-icon-size: 20px;
    $add-icon-color: get_color('neutral', 0);

    .forms-add-icon,
    .forms-add-icon svg {
      width: $add-icon-size;
      height: $add-icon-size;

      path:last-child {
        fill: $add-icon-color;
        stroke: $add-icon-color;
      }
    }

    .forms-add-icon,
    .forms-drag-icon,
    .forms-del-icon {
      visibility: hidden;
    }
    &:hover {
      .forms-add-icon,
      .forms-drag-icon,
      .forms-del-icon {
        visibility: visible !important;
      }
    }

    .conditional-field-row {
      .forms-conditional-add-icon,
      .forms-conditional-add-icon svg {
        width: $add-icon-size;
        height: $add-icon-size;

        path:last-child {
          fill: $add-icon-color;
          stroke: $add-icon-color;
        }
      }

      .forms-conditional-add-icon,
      .forms-conditional-del-icon {
        visibility: hidden;
      }
      &:hover {
        .forms-conditional-add-icon,
        .forms-conditional-del-icon {
          visibility: visible !important;
        }
      }
    }

    .table-field-row {
      .forms-table-add-icon,
      .forms-table-add-icon svg {
        width: $add-icon-size;
        height: $add-icon-size;

        path:last-child {
          fill: $add-icon-color;
          stroke: $add-icon-color;
        }
      }

      .forms-table-add-icon,
      .forms-table-del-icon {
        visibility: hidden;
      }
      &:hover {
        .forms-table-add-icon,
        .forms-table-del-icon {
          visibility: visible !important;
        }
      }
    }

    .field-configuration {
      .subsection-field {
        background: get_color('neutral', 92) !important;
        font-size: 17px !important;
        > span,
        > input {
          font-size: 17px !important;
        }
        > input {
          border: 2px solid get_color('primary', 60) !important;
          border-radius: 3px;
        }
      }

      .select-options-container {
        width: 85%;
        max-width: 600px;
      }

      .new-select-option-input .add-icon svg {
        width: 24px;
        height: 24px;
      }
    }

    .tooltip-field-question-icon {
      height: 12px;
      width: 12px;
    }

    .bp3-switch.primary-blue-switch input {
      $unchecked-color: get_color('neutral', 100);
      $checked-color: get_color('primary', 40);
      ~ .bp3-control-indicator::before {
        background: $unchecked-color !important;
      }
      &:checked ~ .bp3-control-indicator {
        background-color: $checked-color !important;
        &::before {
          background: $unchecked-color !important;
          border-color: $checked-color !important;
        }
      }
    }
  }

  .permit-form-preview {
    flex-basis: $PERMIT_FORM_PREVIEW_WIDTH;
    max-width: $PERMIT_FORM_PREVIEW_WIDTH;
    background-color: get_color('neutral', 30);

    .permit-form-fields {
      background-color: get_color('neutral', 100);
      border-radius: 4px 4px 0 0;

      .fields-section {
        pointer-events: none !important;
      }
    }
  }

  .fixed-loader {
    position: fixed;
    top: 50%;
    margin-left: calc(
      (100% - #{$SETTINGS-LEFT-SIDEBAR-WIDTH + $PERMIT_FORM_PREVIEW_WIDTH}) / 2 -
        #{$loader-offset}
    );
  }

  .permit-type-workflow {
    i.bic-workflow-icon {
      width: 21px;
      height: 20px;
    }

    .inspection-frequency {
      width: 50px;
    }

    .workflow-configurator {
      &-step {
        width: 550px;

        &-line {
          height: 110px;
        }
        &-last-line {
          height: 55px;
        }

        .automatic-start-switch.bp3-switch.primary-blue-switch {
          margin-bottom: 0 !important;
          $toggle-size: 18px !important;
          height: $toggle-size;
          width: $toggle-size;

          .bp3-control-indicator,
          .bp3-control-indicator::before {
            height: $toggle-size;
            width: $toggle-size;
          }

          input {
            $unchecked-color: get_color('neutral', 100);
            $checked-color: get_color('primary', 40);
            ~ .bp3-control-indicator::before {
              background: $unchecked-color !important;
            }
            &:checked ~ .bp3-control-indicator {
              background-color: $checked-color !important;
              &::before {
                background: $unchecked-color !important;
                border-color: $checked-color !important;
              }
            }
          }
        }
      }

      .inspection-frequency-input {
        $input-height: 32px !important;
        max-height: $input-height;

        > div {
          margin-bottom: 0 !important;
        }

        .bare-input-appearance {
          max-height: $input-height;
          background: none !important;
          font-size: 14px !important;
        }
      }

      .add-step-btn .bp3-active {
        background: get_color('neutral', 92);
      }

      .step-menu-btn .bp3-active {
        background: get_color('neutral', 84);
      }
    }
  }

  .drag-placeholder {
    height: 3px;
    right: 0;
    transform: translateY(-50%);
    top: 0;
    margin: 0 22px 0 16px;
    z-index: 120;
  }

  .forms-tabs-container {
    $forms-tab-container-height: 48px;

    min-height: $forms-tab-container-height;
    max-height: $forms-tab-container-height;

    .form-selector-field {
      min-width: 160px;
      max-width: 300px;
    }

    .forms-tab-item {
      $forms-tab-height: 100%;
      $forms-tab-color: get_color('primary', 50);

      line-height: 20px;
      height: $forms-tab-height;
      border-bottom: 1px solid get_color('neutral', 100);
      font-weight: 500;

      &:hover {
        background-color: get_color('neutral', 92);
        border-color: get_color('neutral', 92);
      }
      &:active {
        background-color: get_color('neutral', 88);
        border-color: get_color('neutral', 88);
        transition: background-color 0.2s ease;
      }

      &.active {
        color: $forms-tab-color;
        border-bottom: 2px solid $forms-tab-color;

        &:hover {
          background-color: get_color('primary', 96);
        }
        &:active {
          background-color: get_color('primary', 92);
          transition: background-color 0.2s ease;
        }
      }
    }
  }

  .show-in-app-icon svg {
    height: 12px;
    width: 12px;

    path {
      fill: get_color('neutral', 30) !important;
    }
  }
}

.new-permit-type-form.bp3-dialog {
  width: 60%;
  max-height: 85vh;
  padding: 0;

  .bp3-dialog-header {
    .bp3-heading {
      font-size: 16px;
    }
  }

  .bp3-dialog-body {
    margin: 5px 10px;
    .new-type-inputs {
      max-width: 400px;
    }
  }

  .submit-form-btn {
    max-width: 50%;
  }
}

.form-fields-modal {
  &.bp3-popover-out-of-boundaries {
    visibility: hidden;
    pointer-events: none;
  }
  .form-fields-modal-content {
    max-height: 60vh;
  }
}

.bp3-popover.workflow-notified-users-modal {
  min-width: 500px;
  max-width: 500px;
}

.bp3-popover.bic-step-rules-modal {
  min-width: 500px;
  max-width: 60%;

  .ball-in-court-rules-table .bic-step-rules {
    max-height: 55vh;
  }
}

.workflow-step-descriptions-modal.bp3-dialog {
  width: 70%;
  max-height: 85vh;
  padding: 0;
}
