@import '~/client/src/shared/theme.module';

.confirm-items-dialog {
  border: 2px solid get_color('neutral', 50);
  border-radius: 3px;
  background-color: get_color('neutral', 100);

  &-table {
    padding: 30px;
  }
  &-row {
    padding: 2px 0;
    color: get_color('neutral', 50);
    &-header {
      font-weight: 600;
      color: get_color('neutral', 0);
    }
  }
  &-separator {
    border-width: 0;
    border-bottom: 1px solid get_color('grey', 84);
  }
  &-col {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    &:first-child {
      width: 160px;
      padding-right: 10px;
    }
  }
}
