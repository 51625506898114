@import '~/client/src/shared/theme.module';

$datepicker-height: 487px;

.delivery-workflow-date-picker {
  top: calc((100% - #{$datepicker-height}) / 2);
  border: 1px solid get_color('neutral', 84);
  box-shadow: 0px 20px 20px -20px get_color('neutral', 0, 0.6);

  &.time-only {
    width: 450px !important;
  }

  &-container.shown {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: get_color('neutral', 0, 0.3);
    z-index: 1;

    .date-picker-input {
      &.date {
        flex-basis: 200px;
      }
      &.time {
        margin-left: 5px;
        flex-basis: 100px;
      }

      input {
        border: none;
        border-bottom: 1px solid get_color('neutral', 84);
        font-size: 16px;
        color: get_color('neutral', 84);

        &.selected {
          color: get_color('neutral', 0);
        }
      }
    }
  }
}
