@import '~/client/src/shared/theme.module';

.announcement-dialog {
  width: 800px;
  height: 90%;

  &-header {
    background-color: get_color('neutral', 96);
    z-index: 1;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid get_color('grey', 88);
    justify-content: space-between;

    i[class$='-icon'] {
      display: inline-flex;
      svg path {
        fill: get_color('primary', 0, 0.38);
      }
    }
    .header-icon svg {
      width: 24px;
      height: 24px;
    }
    .cross-icon {
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
        path:last-child {
          fill: get_color('primary', 0, 0.38);
        }
      }
    }
    .navigation-wrapper {
      display: flex;
      align-items: center;
    }
    .navigation-icon svg {
      width: 20px;
      height: 20px;
    }
    .navigation-title {
      font-size: 12px;
    }
  }

  &-content {
    height: calc(100% - 53px);
    p {
      margin-bottom: 0;
    }
  }

  &-footer {
    box-shadow: 0px -1px 1px 0 get_color('neutral', 0, 0.76),
      -1px -2px 1px -1px get_color('neutral', 0, 0.76),
      -4px -3px 3px 0 get_color('neutral', 0, 0.76);
  }

  .announcement-title-icon {
    svg {
      transform: scale(1.2);
      circle {
        fill: get_color('error', 40);
      }
    }
  }

  .announcement-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
  }

  .action-menu {
    box-shadow: -4px 5px 6px -2px get_color('primary', 0);
    width: 120px;
    .option {
      height: 30px;
      svg g path,
      svg g polygon {
        fill: get_color('primary', 50);
      }
    }
  }

  .files-n-toolbar-container {
    padding-left: 65px;
  }

  img {
    max-width: 100%;
  }
}

.bp3-portal {
  z-index: 103;
}
