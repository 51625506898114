@import '~/client/src/shared/theme.module';

button#new-delivery-action {
  width: 136px;
  height: 32px;

  background-color: get_color('primary', 30);
  border-color: get_color('primary', 30);
  color: get_color('neutral', 100);

  svg path {
    stroke: get_color('neutral', 100);
    fill: get_color('neutral', 100);
  }

  &.active,
  &:hover {
    background-color: get_color('neutral', 100);
    color: get_color('primary', 30);

    svg path {
      stroke: get_color('primary', 30);
      fill: get_color('primary', 30);
    }
  }
}
