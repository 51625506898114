@import '~/client/src/shared/theme.module';

.sitemap-items-color-picker {
  .value-container {
    background-color: get_color('neutral', 96);
    border-radius: 2px;
    width: 84px;
    cursor: pointer;

    .color-option {
      display: flex;
      flex-basis: 56px;
      height: 32px;
      margin: 0;
      z-index: 2;

      &.opacity-background {
        background-image: linear-gradient(
            45deg,
            get_color('neutral', 84) 25%,
            transparent 25%
          ),
          linear-gradient(-45deg, get_color('neutral', 84) 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, get_color('neutral', 84) 75%),
          linear-gradient(-45deg, transparent 75%, get_color('neutral', 84) 75%);
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
        position: absolute;
        width: 56px;
        z-index: 1;
        top: 0;
        left: 0;
      }
    }
  }

  .select-arrow-down svg {
    width: 12px;
    height: 12px;
  }

  .bp3-popover.bp3-minimal,
  .bp3-popover.bp3-minimal > .bp3-popover-content {
    border-radius: 8px;
  }
}
