@import '~/client/src/shared/theme.module';

.activities-map .sitemaps-gallery {
  z-index: 1;

  .cross-icon,
  .tags-list {
    z-index: 2;
  }

  .selected-card {
    background-color: get_color('primary', 96);
    box-shadow: 0px 0px 34px 0px get_color('grey', 50);
  }
}
