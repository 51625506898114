@import '~/client/src/shared/theme.module';

$navigation-header-height: 131px;

.project-setup-mainframe .delivery-restriction-content {
  height: calc(100% - #{$navigation-header-height});

  .restriction-table {
    max-height: 100%;

    &-content {
      width: fit-content;

      .cell .hierarchy-delivery-attribute-tag-content {
        max-width: 160px;
      }

      .levels-hierarchy .cell .hierarchy-delivery-attribute-tag-content {
        max-width: 150px;
      }
    }

    .cell {
      flex-basis: 220px;
      min-width: 220px;
      max-width: 220px;
      height: 100%;

      padding: 12px;
      border-right: 1px solid get_color('neutral', 84);
      border-top: 1px solid get_color('neutral', 84);

      &.legend {
        flex-basis: 110px;
        min-width: 110px;
        max-width: 110px;
      }

      .caret-down {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;

        border-top: 6px solid get_color('neutral', 0);
      }

      .caret-right {
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;

        border-left: 6px solid get_color('neutral', 0);
      }

      .hierarchy-delivery-attribute-tag-content {
        max-width: 130px;
      }
    }

    .cell-width {
      flex-basis: 220px;
      min-width: 220px;
      max-width: 220px;

      &.legend {
        flex-basis: 110px;
        min-width: 110px;
        max-width: 110px;
      }
    }

    .dot {
      display: inline-block;
      border: 1px solid get_color('neutral', 84);
      border-radius: 3px;
      width: 6px;
      height: 6px;
    }

    .remove-icon svg {
      width: 20px;
      height: 20px;

      path:last-child {
        fill: get_color('neutral', 50);
      }
    }

    .restriction-modal {
      max-height: 700px;
      background-color: get_color('neutral', 100);
      width: 375px;
      left: -78px;
      top: 40px;
      border-radius: 4px;
      box-shadow: 0 20px 50px -20px get_color('neutral', 50);
      z-index: 10;
    }
  }
}
