@import '~/client/src/shared/theme.module';

$saved-filters-container-height: 385px;
$name-cell-width: 349px;
$count-cell-width: 45px;

.label {
  display: inline-flex;

  .name-cell {
    width: $name-cell-width;
    overflow: hidden;
  }

  .count-cell {
    width: $count-cell-width;
    justify-content: center;
    color: get_color('primary', 30);
  }

  .arrow-icon {
    color: get_color('primary', 30);
    cursor: pointer;
  }
}

.saved-filters {
  &-handle-button {
    max-width: 70px;
    min-width: 70px;

    span {
      padding: 0 10px;
    }
  }

  .filter-container {
    max-height: $saved-filters-container-height;

    &-content {
      overflow: auto;
      height: 265px;

      .name {
        justify-content: space-between;

        .input {
          max-width: 300px;
        }
      }
    }
  }
}
