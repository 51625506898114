@import '~/client/src/shared/theme.module';

.react-draggable {
  width: 15px;
  position: absolute;
  top: 0;
  left: 0;

  .move-icon {
    position: absolute;
    top: 5px;
    left: -20px;
    pointer-events: none;
    svg {
      fill: inherit;
      path {
        fill: inherit;
      }
    }
  }

  .ql-toolbar.ql-snow {
    background-color: get_color('neutral', 100);
    height: 100%;
    width: 25px;
    cursor: move;
    position: absolute;
    top: 0;
    left: -25px;
    .ql-active {
      background: get_color('neutral', 84) !important;
    }
  }

  &.text-box {
    z-index: 100;
    &.show-textbox {
      .ql-container {
        background: get_color('neutral', 100);
      }
    }
  }
  .ql-container {
    font-size: inherit;

    padding: 2px 4px;
    border: 2px solid get_color('primary', 70);
    border-top: 2px solid get_color('primary', 70) !important;
    white-space: nowrap;
    text-shadow: 1px 0 0 get_color('neutral', 100),
      -1px 0 0 get_color('neutral', 100), 0 1px 0 get_color('neutral', 100),
      0 -1px 0 get_color('neutral', 100), 1px 1px get_color('neutral', 100),
      -1px -1px 0 get_color('neutral', 100),
      1px -1px 0 get_color('neutral', 100), -1px 1px 0 get_color('neutral', 100);
    width: fit-content;
    * {
      outline: none;
    }
  }
}
