@import '~/client/src/shared/theme.module';

.md-events-bar {
  overflow-x: auto;
  align-items: start;

  &-item {
    &:last-child {
      border-right: 1px solid get_color('grey', 84);
    }

    &-event {
      min-height: 18px;
      margin-bottom: 1px;

      &:first-child {
        margin-top: 1px;
      }

      &.active {
        background-color: currentColor !important;

        .label-container,
        .label-container * {
          color: get_color('neutral', 100) !important;
        }

        .form-type-icon {
          path:nth-child(2) {
            fill: get_color('primary', 0) !important;
          }
        }
      }

      &.view-range {
        &.end {
          border-right-style: dotted !important;
          border-right-width: 2px !important;
        }

        &.start {
          border-left-style: dotted !important;
          border-left-width: 2px !important;
        }
      }
    }

    .card-holder {
      top: 14px;
    }

    .opaque-underlay {
      width: calc(100% + 1px);
    }

    .event-icon svg {
      height: 14px;
      width: 14px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .permit-card {
    min-height: 130px;
    height: unset !important;
    width: 300px;
  }

  &.expanded {
    max-height: 30%;
  }
}
