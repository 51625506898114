@import '~/client/src/shared/theme.module';

.gantt-date-picker {
  z-index: 100;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  border: 1px solid get_color('neutral', 70);

  &-top-btn {
    &-container {
      display: flex;
      padding: 10px;
    }

    color: get_color('primary', 30);
    border: 1px solid get_color('primary', 30);
    cursor: pointer;
    border-radius: 3px;
    padding: 0 5px;
    margin-right: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &-icon {
      display: inline-flex;
      background: url('/static/icons/calendar.svg');
      width: 14px;
      height: 14px;
      margin-left: 15px;
    }

    &.active {
      background-color: get_color('primary', 30);
      color: get_color('neutral', 100);
    }

    &.disabled {
      color: get_color('grey', 40);
      border-color: get_color('grey', 40);
      opacity: 0.3;
      cursor: default;
    }
  }
}
