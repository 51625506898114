@import '~/client/src/shared/theme.module';

$scroll-resizer-width: 8px;

.columns-config-menu {
  z-index: 110;
  max-height: calc(100vh - 80px);
  height: auto;
  width: 384px;
  top: 32px;
  border-radius: 8px;
  background-color: get_color('neutral', 100);

  .bp3-control {
    margin-bottom: 0 !important;
    padding-right: 15px !important;
  }

  .columns-list {
    height: calc(100% - 70px);
    min-height: 100%;

    .sortable-list-holder {
      &::-webkit-scrollbar {
        width: $scroll-resizer-width;
        height: $scroll-resizer-width;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: get_color('neutral', 70);
      }
    }

    .icon-grey svg g {
      fill: get_color('neutral', 40);
    }
  }

  .search-bar-container {
    border-radius: 8px;
    background-color: get_color('neutral', 100);
  }
}

.sortable-item.full {
  z-index: 120 !important;
}
