@import '~/client/src/shared/theme.module';

.choose-sheet-dialog {
  width: 550px;
  border: 1px solid get_color('grey', 88);
  border-radius: 2px;
  background-color: get_color('neutral', 100);

  box-shadow: none;

  .bp3-dialog-header {
    margin: 0 30px;
    padding: 0;
    .bp3-heading {
      color: get_color('neutral', 0);
      font-size: 16px;
      padding: 15px 0;
    }
  }

  .bp3-dialog-body {
    margin: 20px 30px;
  }

  .bp3-dialog-footer {
    text-align: center;
  }

  &-btn {
    width: 135px;
    border: 1px solid get_color('primary', 30);

    &:hover {
      box-shadow: none;
    }

    &.blue-btn.bp3-button {
      color: get_color('neutral', 100);
      background: get_color('primary', 30);
      &:hover {
        background: get_color('primary', 30);
      }

      &.disabled {
        border: 1px solid get_color('neutral', 84);
        color: get_color('neutral', 84);
        cursor: default;
        background: get_color('neutral', 100);
      }
    }

    &.white-btn.bp3-button {
      color: get_color('primary', 30);
      background: get_color('neutral', 100);
      margin-right: 15px;
    }
  }
}
