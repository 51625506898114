@import '~/client/src/shared/theme.module';

$additional-section-width: 100px;
$additional-section-text-width: 150px;
$toggle-control-width: 340px;
$option-width: 140px;
$option-height: 32px;
$key-section-height: 32px;

.delivery-details-configuration {
  .section-title-row {
    display: flex;
    align-items: center;
  }
  .additional-section {
    max-width: $additional-section-width;
  }
  .additional-section-text {
    min-width: $additional-section-text-width;
  }
  .toggle-control {
    pointer-events: none;
    max-width: $toggle-control-width;
  }

  .section-wrapper {
    padding-left: 44px;
  }

  .bp3-switch.primary-blue-switch {
    color: get_color('neutral', 70);
    margin-bottom: 0;

    .bp3-control-indicator {
      width: 43px;
      height: 28px;
      box-sizing: border-box;
      border-radius: 14px;
      border: 1px solid get_color('neutral', 70);
      background-color: get_color('neutral', 70) !important;

      &::before {
        box-sizing: border-box;
        width: 28px;
        height: 28px;
        top: -1px;
        left: -1px;
        margin: 0;
        border-radius: 14px;
        border: 1px solid get_color('neutral', 70);
        background: get_color('neutral', 100);
        box-shadow: none;
        outline: none;
      }
    }

    input:checked ~ .bp3-control-indicator {
      background-image: none;
      border-color: get_color('primary', 50);
      color: get_color('primary', 50);
      background-color: get_color('primary', 50) !important;
      box-shadow: none;
      outline: none;

      &::before {
        border-color: get_color('primary', 50);
        background: url(/static/icons/checked-palette-blue.svg) no-repeat center
          center;
        background-color: get_color('neutral', 100);
        background-size: 14px;
        left: 1em;
      }
    }
  }

  .section-block-toggle,
  .dialog-toggle-item {
    height: $key-section-height;
  }

  .section-block-toggle {
    .bp3-overlay.bp3-overlay-inline {
      overflow: hidden !important;
    }
  }

  i.section-check-icon,
  i.section-check-icon svg {
    height: 14px;
    width: 14px;
  }

  .select-block .option {
    width: $option-width;
    height: $option-height;
    background-color: get_color('neutral', 96);
    color: get_color('neutral', 60);
    i {
      flex-grow: 0 !important;
      margin-right: 5px;
      height: 16px;
    }
    &.active svg path {
      fill: get_color('neutral', 100);
    }
    &:not(.active) svg path {
      fill: get_color('neutral', 60);
    }
    &.positive.active {
      color: get_color('neutral', 100);
      background-color: get_color('primary', 30);
    }
    &.negative.active {
      background-color: get_color('neutral', 60);
      color: get_color('neutral', 100);
    }
  }
}
