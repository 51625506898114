@import '~/client/src/shared/theme.module';

$loading-spinner-size: 20px;
$min-single-row-width: 864px;
$tooltip-animation-time: 2s;
$tooltip-transition-time: 75ms;
$tooltip-top-offset: 40px;
$tooltip-height: 24px;
$tooltip-x-transform: -30%;
$map-view-tooltip-offset: 5px;

$item-height: 30px;
$item-width: 31px;

$gantt-view-icon: '/static/icons/gantt-icon.svg';
$gantt-view-icon-selected: '/static/icons/gantt-icon-selected.svg';
$gantt-view-icon-disabled: '/static/icons/gantt-icon-disabled.svg';

$calendar-view-icon: '/static/icons/calendar-icon.svg';
$calendar-view-icon-selected: '/static/icons/calendar-icon-selected.svg';
$calendar-view-icon-disabled: '/static/icons/calendar-icon-disabled.svg';

$list-view-icon: '/static/icons/list-icon.svg';
$list-view-icon-selected: '/static/icons/list-icon-selected.svg';
$list-view-icon-disabled: '/static/icons/list-icon-disabled.svg';

$map-view-icon: '/static/icons/map-icon.svg';
$map-view-icon-selected: '/static/icons/map-icon-selected.svg';
$map-view-icon-disabled: '/static/icons/map-icon-disabled.svg';

@mixin loading-screen {
  background-color: get_color('primary', 30);
  color: get_color('neutral', 100);
  &:before {
    content: '';
    opacity: 0.6;
    background-color: get_color('neutral', 100);
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100vw;
    height: 100vh;
    @media screen and (max-width: $min-single-row-width) {
      left: 0;
    }
  }
  &:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 40vh;
    right: 50vw;
    width: $loading-spinner-size;
    height: $loading-spinner-size;
    border-radius: 50%;
    border: 2px solid get_color('neutral', 70);
    border-top-color: get_color('grey', 40);
    animation: spinner 0.6s linear infinite;
  }
}

@mixin menu-item($image-url, $disabled-image-url: '') {
  &.active {
    pointer-events: none;
    background: get_color('primary', 30) url($image-url) -1px -2px;
    color: get_color('neutral', 100);
  }

  &.disabled {
    pointer-events: none;
    background: url($disabled-image-url) -1px -2px;
  }

  &:not(.active) {
    &:active {
      @include loading-screen;
    }
  }
}

.views-toggle {
  display: inline-flex;
  background-color: get_color('neutral', 100);
  z-index: 99;
  @media screen and (min-width: $SMALL-SCREEN-WIDTH) {
    margin-left: 30px;
  }
  @media screen and (max-width: $SMALL-SCREEN-WIDTH) {
    margin-left: 5px;
  }
  box-shadow: 0 6px 4px -4px get_color('neutral', 50);

  .item-wrapper {
    height: $item-height;

    &:hover {
      .tooltip {
        animation: tooltipAnimation $tooltip-animation-time;
      }
    }

    .tooltip {
      opacity: 0;
      position: absolute;
      height: $tooltip-height;
      top: $tooltip-top-offset;
      transform: translateX($tooltip-x-transform);
      z-index: 99;
    }
  }

  &-item {
    display: inline-block;
    width: $item-width;
    height: $item-height;

    background-color: get_color('neutral', 100);
    background-position-x: -1px;
    background-position-y: -2px;

    border: 1px solid get_color('grey', 84);
    border-radius: 0;

    &.active {
      border: 1px solid get_color('primary', 30);
    }

    &:active {
      border: 1px solid get_color('primary', 30);
      @include loading-screen;
    }

    &:hover {
      border: 1px solid get_color('primary', 30, 1);
    }
  }

  &-gantt {
    border-radius: 3px 0 0 3px;
    background-image: url($gantt-view-icon);

    @include menu-item($gantt-view-icon-selected, $gantt-view-icon-disabled);
  }

  &-calendar {
    border-radius: 3px 0 0 3px;
    background-image: url($calendar-view-icon);

    @include menu-item(
      $calendar-view-icon-selected,
      $calendar-view-icon-disabled
    );
  }

  &-list {
    background-image: url($list-view-icon);

    @include menu-item($list-view-icon-selected, $list-view-icon-disabled);
  }

  &-map {
    border-radius: 0 3px 3px 0;
    background-image: url($map-view-icon);

    @include menu-item($map-view-icon-selected, $map-view-icon-disabled);
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
}

@keyframes tooltipAnimation {
  0%,
  100% {
    opacity: 0;
  }
  15%,
  90% {
    opacity: 1;
  }
}
