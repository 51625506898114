@import '~/client/src/shared/theme.module';

$form-width: 450px;

%user-profile-preview-base {
  .name-row {
    font-size: 16px !important;
    color: get_color('primary', 0) !important;
  }

  .brief-info-row {
    font-size: 12px !important;
    color: get_color('neutral', 50) !important;
  }
}

.form-setup {
  &-result-list {
    width: $form-width;

    .user-profile-preview {
      @extend %user-profile-preview-base;
    }
  }

  &-search-input {
    width: $form-width;
  }

  &-search-input-result {
    position: absolute;
    width: $form-width;
    border-top: none;
    box-shadow: 10px 10px 15px get_color('neutral', 10);
    outline: none;
    z-index: 1;
    margin-top: -20px;

    .result-row {
      .user-profile-preview {
        @extend %user-profile-preview-base;
      }

      &.current {
        background-color: get_color('primary', 92);
      }
    }
  }
}
