@import '~/client/src/shared/theme.module';

.procore-users-dialog {
  width: 1130px;
  &.narrow {
    width: 600px;
  }
  max-width: calc(100vw - 200px);
  height: 800px;
  max-height: calc(100vh - 200px);
  background-color: get_color('neutral', 100);
  &-btns-item.primary-blue-btn {
    width: 200px;
  }
  .content {
    flex-grow: 1;
    .procore-list-item {
      &:hover {
        background-color: get_color('primary', 92);
      }
    }
  }
  .table-container {
    width: 1090px !important;
  }
  .ReactVirtualized__Table {
    &__Grid {
      outline: none;
    }
    &__rowColumn {
      height: 100%;
      margin: 0 !important;
    }
    &__row {
      &:hover {
        background-color: get_color('primary', 92);
      }
    }
  }
}
