@import '~/client/src/shared/theme.module';

.material-category {
  &-editor {
    min-height: 95px;
    &.large {
      min-height: 450px;
    }
    .category-checkbox {
      display: flex;
      flex: 0 0 60px;
    }
  }

  &-table {
    .p-checkbox-box {
      border: 1px solid get_color('neutral', 30) !important;
      color: get_color('neutral', 30);
      font-weight: normal;
    }
    .header-row {
      border-bottom: 1px solid get_color('neutral', 70);
      height: 26px;
    }
    .ReactVirtualized__Table__row {
      background: none;
      &:hover {
        background-color: get_color('primary', 92, 0.5);
      }
      .category-cell .absolute-block {
        height: 100%;
        background-color: get_color('neutral', 96);
        border-bottom: 1px solid get_color('neutral', 84);
      }
      .ReactVirtualized__Table__rowColumn {
        height: 100%;
        margin-right: 0 !important;
        &:nth-child(2) {
          flex-grow: 1 !important;
        }
      }
    }
    .ReactVirtualized__Table__headerColumn:first-of-type,
    .ReactVirtualized__Table__rowColumn:first-of-type {
      margin-left: 0 !important;
    }
  }
}
